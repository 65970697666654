import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";

export class Tabset_user extends Component {
  toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);

    return t.toLocaleDateString("en-US");
  };

  renderOrderStatus = (lotNo) => {
    if (this.props.allLots.length > 0) {
      const lotObj = this.props.allLots.find((lot) => lot.lotNo === lotNo);

      let backgroundColor;
      let icofont;
      if (lotObj) {
        if (lotObj.shipmentStatus === "Local Customs") {
          backgroundColor = "#f99322";
          icofont = "icofont-hand";
        }
        if (lotObj.shipmentStatus === "Local Warehouse") {
          backgroundColor = "darkgreen";
          icofont = "icofont-tick-boxed";
        }
        if (lotObj.shipmentStatus === "Ready to Fly") {
          backgroundColor = "#b11ad8";
          icofont = "icofont-airplane-alt";
        }
        if (lotObj.shipmentStatus === "China Customs") {
          backgroundColor = "#ffbc58";
          icofont = "icofont-police";
        }
        if (lotObj.shipmentStatus === "China Warehouse") {
          backgroundColor = "#13c9ca";
          icofont = "icofont-building-alt";
        }
      }

      return (
        <div
          className=" icon-left no-shadow align-self-center my_parcel_update_button"
          style={{
            // background: backgroundColor,
            fontSize: "85%",
            fontFamily: "sans-serif",
            // color: "white",
            padding: "7px",
            color: backgroundColor,
          }}
        >
          {/* <i className={icofont}></i> {lotObj.shipmentStatus} */}
        </div>
      );
    }
    return null;
  };
  getPaymentMethod = (recharge) => {
    if (recharge.paymentMethod === "Cash") {
      return "Cash";
    }
    if (recharge.paymentMethod === "Bank") {
      return recharge.bankName;
    }
    if (recharge.paymentMethod === "Mobile Banking") {
      return recharge.mobileBanking;
    }
  };
  getColor = (booking) => {
    if (booking.bookingStatus === "Success") {
      return "green";
    }
    if (booking.bookingStatus === "Pending") {
      return "orange";
    }
    if (booking.bookingStatus === "Reject") {
      return "red";
    }
    if (booking.bookingStatus === "Received") {
      return "purple";
    }
  };

  renderProductName = (bookingObj) => {
    if (bookingObj.shipmentMethod == "D2D") {
      return bookingObj.productType;
    } else {
      return bookingObj.productName;
    }
  };

  renderOtherInformation = (bookingObj) => {
    if (bookingObj.shipmentMethod == "D2D") {
      return (
        <div>
          <p style={{ marginBottom: "0px" }}>
            Ship By:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.shipBy}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Ship From:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.shipFrom}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Per Kg:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.perKg}RMB
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Total Cost:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.totalCost ? bookingObj.totalCost : bookingObj.result}
              RMB
            </span>
          </p>
          <img
            style={{
              maxHeight: "200px",
              maxWidth: "200px",
              marginTop: "20px",
            }}
            src={bookingObj.imageUrl}
          />
        </div>
      );
    }
    if (bookingObj.shipmentMethod == "Express") {
      return (
        <div>
          <p style={{ marginBottom: "0px" }}>
            Parcel To:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.parcelTo}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Parcel Type:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.parcelType}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Parcel Box:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.parcelBox}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Total Cost:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.totalCost ? bookingObj.totalCost : bookingObj.total}
              RMB
            </span>
          </p>
          <img
            style={{
              maxHeight: "200px",
              maxWidth: "200px",
              marginTop: "20px",
            }}
            src={bookingObj.imageUrl}
          />
        </div>
      );
    }
    if (bookingObj.shipmentMethod == "Freight") {
      return (
        <div>
          <p style={{ marginBottom: "0px" }}>
            Ship By:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.shipBy}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Ship From:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.shipFrom}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Ship To:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.shipToFreight}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Port of Origin:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.portOfOrigin}
            </span>
          </p>
          <p style={{ marginBottom: "0px" }}>
            Port of Destination:&nbsp;
            <span style={{ color: "rgb(18 201 202)", fontSize: "130%" }}>
              {bookingObj.portOfDestination}
            </span>
          </p>
          <div className="row ">
            <div className="col">
              <div
                className="center-head"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "0px",
                }}
              >
                <span
                  className="bg-light-gray"
                  style={{
                    fontSize: "130%",
                    fontWeight: "bold",
                    marginBottom: "0px",
                    padding: "0px",
                    color: bookingObj.pick_up ? "white" : "gainsboro",
                  }}
                >
                  <i
                    className="icofont-truck-loaded"
                    style={{
                      fontSize: "100%",
                      border: bookingObj.pick_up
                        ? "1px solid white"
                        : "1px solid gainsboro",
                      borderRadius: "10rem",
                      padding: "5px",
                      backgroundColor: bookingObj.pick_up ? "orange" : "white",
                    }}
                  ></i>
                </span>
                <span
                  className="bg-light-gray"
                  style={{
                    fontSize: "130%",
                    fontWeight: "bold",
                    marginBottom: "0px",
                    padding: "0px",
                    color: bookingObj.port_of_origin ? "white" : "gainsboro",
                  }}
                >
                  <i
                    className="icofont-home"
                    style={{
                      fontSize: "100%",
                      border: bookingObj.port_of_origin
                        ? "1px solid white"
                        : "1px solid gainsboro",
                      borderRadius: "10rem",
                      padding: "5px",
                      backgroundColor: bookingObj.port_of_origin
                        ? "orange"
                        : "white",
                    }}
                  ></i>
                </span>
                <span
                  className="bg-light-gray"
                  style={{
                    fontSize: "130%",
                    fontWeight: "bold",
                    marginBottom: "0px",
                    padding: "0px",
                    color: bookingObj.port_of_destination
                      ? "white"
                      : "gainsboro",
                  }}
                >
                  <i
                    className="icofont-ui-home"
                    style={{
                      fontSize: "100%",
                      border: bookingObj.port_of_destination
                        ? "1px solid white"
                        : "1px solid gainsboro",
                      borderRadius: "10rem",
                      padding: "5px",
                      backgroundColor: bookingObj.port_of_destination
                        ? "orange"
                        : "white",
                    }}
                  ></i>
                </span>
                <span
                  className="bg-light-gray"
                  style={{
                    fontSize: "130%",
                    fontWeight: "bold",
                    marginBottom: "0px",
                    padding: "0px",
                    color: bookingObj.delivery ? "white" : "gainsboro",
                  }}
                >
                  <i
                    className="icofont-truck-alt"
                    style={{
                      fontSize: "100%",
                      border: bookingObj.delivery
                        ? "1px solid white"
                        : "1px solid gainsboro",
                      borderRadius: "10rem",
                      padding: "5px",
                      backgroundColor: bookingObj.delivery ? "orange" : "white",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  render() {
    const { user, bookingsArray, rechargesArray, parcelsArray, paymentsArray } =
      this.props;
    const newRechargesArray = rechargesArray.map((recharge) => {
      return { ...recharge, date: recharge.rechargedAt };
    });
    console.log(newRechargesArray);
    const newPaymentsArray = paymentsArray.map((payment) => {
      return { ...payment, date: payment.paidAt };
    });
    console.log(newPaymentsArray);

    const transactions = [...newRechargesArray, ...newPaymentsArray];
    const transactionArray = transactions.map((transaction) => {
      const dateToSort = new Date(transaction.date);
      return { ...transaction, dateToSort };
    });
    transactionArray.sort(
      (a, b) => b.dateToSort.getTime() - a.dateToSort.getTime()
    );

    return (
      <Fragment>
        <Tabs>
          <TabList
            className="nav nav-tabs tab-coupon"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Tab className="nav-link">Account info</Tab>
            <Tab className="nav-link">Bookings</Tab>
            <Tab className="nav-link">Parcels</Tab>
            <Tab className="nav-link">Recharge history</Tab>
            <Tab className="nav-link">Payment history</Tab>
            <Tab className="nav-link">Transaction history</Tab>
          </TabList>
          <TabPanel>
            <form className="needs-validation user-add" noValidate="">
              <h4>Account Details</h4>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Name
                </label>
                <div
                  className="col-xl-8 col-md-7"
                  style={{ textTransform: "capitalize" }}
                >
                  {user && user.displayName}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Wallet
                </label>
                <div className="col-xl-8 col-md-7">
                  {user && user.myWallet}RMB
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Status
                </label>
                <div className="col-xl-8 col-md-7">{user && user.status}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Mobile No
                </label>
                <div className="col-xl-8 col-md-7">{user && user.mobileNo}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Email
                </label>
                <div className="col-xl-8 col-md-7">{user && user.email}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Address
                </label>
                <div className="col-xl-8 col-md-7">{user && user.address}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Company
                </label>
                <div className="col-xl-8 col-md-7">{user && user.company}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Total Recharge
                </label>
                <div className="col-xl-8 col-md-7">
                  {user && user.totalRecharge} RMB
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Total Paid
                </label>
                <div className="col-xl-8 col-md-7">
                  {user && user.totalPaid} RMB
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Shipping Mark
                </label>
                <div className="col-xl-8 col-md-7" style={{ color: "#ff8084" }}>
                  ({user && user.userId}-{user ? user.displayName : "Name"})
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Uid
                </label>
                <div className="col-xl-8 col-md-7">{user && user.uid}</div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-md-4">
                  <span>*</span> Created At
                </label>
                <div className="col-xl-8 col-md-7">
                  {user && this.toDateTime(user.createdAt.seconds)}
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Booking Id</th>
                  <th scope="col">Method</th>

                  <th scope="col">Status</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {bookingsArray.length > 0
                  ? bookingsArray.map((booking, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{booking.date}</td>
                        <td>{booking.bookingId}</td>
                        <td>{booking.shipmentMethod}</td>

                        <td
                          style={{
                            color: this.getColor(booking),
                          }}
                        >
                          {booking.bookingStatus}
                        </td>
                        <td>
                          {" "}
                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                              <Popover
                                id={`popover-positioned-bottom`}
                                style={{ minWidth: "35%" }}
                              >
                                <Popover.Title
                                  style={{
                                    backgroundColor: "#ff8084",
                                    color: "white",
                                  }}
                                  as="h3"
                                >{`Booking Id: ${booking.bookingId}`}</Popover.Title>
                                <Popover.Content className="popover-body-container">
                                  <>
                                    <div
                                      style={{
                                        paddingBottom: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        borderBottom: "2px solid gainsboro",
                                      }}
                                    >
                                      {booking.shipmentMethod !== "Express" ? (
                                        <div>
                                          <p style={{ marginBottom: "0px" }}>
                                            Product Type:&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {this.renderProductName(booking)}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Product Name(specific):&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.othersProductName}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Total Weight:&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.weight}
                                              kg
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Total CBM:&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.totalCbm}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Product Quantity:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.productQuantity}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Carton Quantity:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.ctnQuantity}
                                            </span>
                                          </p>
                                          {booking.shipmentMethod ===
                                          "Freight" ? (
                                            <>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                Container Size:&nbsp;{" "}
                                                <span
                                                  style={{
                                                    color: "#ff8084",
                                                    fontSize: "130%",
                                                  }}
                                                >
                                                  {booking.containerSize}
                                                </span>
                                              </p>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                CNF Service:&nbsp;{" "}
                                                <span
                                                  style={{
                                                    color: "#ff8084",
                                                    fontSize: "130%",
                                                  }}
                                                >
                                                  {booking.cnfService}
                                                </span>
                                              </p>
                                            </>
                                          ) : (
                                            <p style={{ marginBottom: "0px" }}>
                                              Brand/Non-Brand:&nbsp;{" "}
                                              <span
                                                style={{
                                                  color: "#ff8084",
                                                  fontSize: "130%",
                                                }}
                                              >
                                                {booking.productBrand}
                                              </span>
                                            </p>
                                          )}

                                          <p style={{ marginBottom: "0px" }}>
                                            Product Contains:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.productContains}
                                            </span>
                                          </p>
                                        </div>
                                      ) : (
                                        <div style={{ maxWidth: "40%" }}>
                                          <p style={{ marginBottom: "0px" }}>
                                            Product Name:&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {this.renderProductName(booking)}
                                            </span>
                                          </p>

                                          <p style={{ marginBottom: "0px" }}>
                                            Product Total Cost:&nbsp;
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.productsTotalCost}
                                              RMB
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Carton Quantity:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.ctnQuantity}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Used/New:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.usedOrNew}
                                            </span>
                                          </p>
                                          <p style={{ marginBottom: "0px" }}>
                                            Product Contains:&nbsp;{" "}
                                            <span
                                              style={{
                                                color: "#ff8084",
                                                fontSize: "130%",
                                              }}
                                            >
                                              {booking.productContains}
                                            </span>
                                          </p>
                                          <div
                                            style={{
                                              marginTop: "20px",
                                              paddingBottom: "20px",
                                            }}
                                          >
                                            <h3 style={{ color: "#ff8084" }}>
                                              Receiver's Information
                                            </h3>
                                            <p style={{ marginBottom: "0px" }}>
                                              Name:&nbsp;
                                              <span
                                                style={{
                                                  color: "rgb(18 201 202)",
                                                  fontSize: "130%",
                                                }}
                                              >
                                                {booking.receiversName}
                                              </span>
                                            </p>
                                            <p style={{ marginBottom: "0px" }}>
                                              Mobile No:&nbsp;
                                              <span
                                                style={{
                                                  color: "rgb(18 201 202)",
                                                  fontSize: "130%",
                                                }}
                                              >
                                                {booking.receiversMobileNo}
                                              </span>
                                            </p>
                                            <p style={{ marginBottom: "0px" }}>
                                              Postcode:&nbsp;
                                              <span
                                                style={{
                                                  color: "rgb(18 201 202)",
                                                  fontSize: "130%",
                                                }}
                                              >
                                                {booking.receiversPostcode}
                                              </span>
                                            </p>
                                            <p style={{ marginBottom: "0px" }}>
                                              Address:&nbsp;
                                              <span
                                                style={{
                                                  color: "rgb(18 201 202)",
                                                  fontSize: "130%",
                                                }}
                                              >
                                                {booking.receiversAddress}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )}

                                      <div
                                        style={{
                                          minHeight: "100%",
                                          borderRight: "2px solid gainsboro",
                                        }}
                                      ></div>
                                      {this.renderOtherInformation(booking)}
                                    </div>
                                    {booking.chinaOffice && (
                                      <div>
                                        <div
                                          style={{
                                            marginTop: "5px",
                                            padding: "5px",
                                            backgroundColor: "#13c9ca",
                                            borderRadius: "10px",
                                            color: "white",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Please send your product to this
                                          address.
                                        </div>
                                        <div
                                          style={{
                                            padding: "20px",
                                            backgroundColor: "purple",
                                            borderRadius: "20px",
                                            textAlign: "center",
                                            margin: "10px auto",
                                            maxWidth: "300px",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {booking.chinaOffice}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <button className="btn btn-primary">show</button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Parcel Id</th>
                  <th scope="col">Lot No</th>
                  <th scope="col">Carton No</th>
                  <th scope="col">Tracking No</th>
                  <th scope="col">Parcel Status</th>
                </tr>
              </thead>
              <tbody>
                {parcelsArray.length > 0
                  ? parcelsArray.map((parcel, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{parcel.parcelId}</td>
                        <td>{parcel.lotNo}</td>
                        <td>{parcel.cartonNo}</td>
                        <td>{parcel.trackingNo}</td>
                        <td>{this.renderOrderStatus(parcel.lotNo)}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Recharge Id</th>
                  <th scope="col">Receit No</th>
                  <th scope="col">Payment Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Recharge By</th>
                </tr>
              </thead>
              <tbody>
                {rechargesArray.length > 0
                  ? rechargesArray.map((recharge, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{recharge.rechargedAt}</td>
                        <td>{recharge.rechargeId}</td>
                        <td>{recharge.receitNo}</td>
                        <td>{this.getPaymentMethod(recharge)}</td>
                        <td>{recharge.amount}RMB</td>
                        <td>{recharge.rechargeBy}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>

          <TabPanel>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Payment Id</th>
                  <th scope="col">Payment Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Paid Invoice</th>
                </tr>
              </thead>
              <tbody>
                {paymentsArray.length > 0
                  ? paymentsArray.map((payment, index) => (
                      <tr
                        style={{ background: "#ff8084", color: "white" }}
                        key={index}
                      >
                        <th scope="row">{index + 1}</th>
                        <td>{payment.paidAt}</td>
                        <td>{payment.paymentId}</td>
                        <td>{payment.paymentMethod}</td>
                        <td>{payment.amount}RMB</td>
                        <td>
                          {payment.paidInvoice.map(
                            (parcelId) => `${parcelId}, `
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Id</th>
                  <th scope="col">Payment Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Recharge By</th>
                  <th scope="col">Paid Invoice</th>
                </tr>
              </thead>
              <tbody>
                {transactionArray.length > 0
                  ? transactionArray.map((transaction, index) =>
                      transaction.paymentMethod !== "ALG wallet" ? (
                        <tr
                          style={{ background: "#28a745", color: "white" }}
                          key={index}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{transaction.rechargedAt}</td>
                          <td>{transaction.rechargeId}</td>

                          <td>{this.getPaymentMethod(transaction)}</td>
                          <td>{transaction.amount}RMB</td>
                          <td>{transaction.rechargeBy}</td>
                          <td></td>
                        </tr>
                      ) : (
                        <tr
                          style={{ background: "#dc3545", color: "white" }}
                          key={index}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{transaction.paidAt}</td>
                          <td>{transaction.paymentId}</td>
                          <td>{transaction.paymentMethod}</td>
                          <td>{transaction.amount}RMB</td>
                          <td></td>
                          <td>
                            {transaction.paidInvoice.map(
                              (parcelId) => `${parcelId}, `
                            )}
                          </td>
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allLots: state.lots.lots,
  };
};
export default connect(mapStateToProps)(Tabset_user);
