import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { ToastContainer, toast } from "react-toastify";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { DollarSign } from "react-feather";
import { Link } from "react-router-dom";
import {
  getAllPurchasingsRedux,
  uploadPurchasingRedux,
  updatePurchasingRedux,
  deletePurchasingRedux,
  getAllEmployeeRedux,
  updateEmployeeRedux,
  handlePurchasingCashInRedux,
  handlePurchasingCashOutRedux,
  uploadTransactionRedux,
  uploadExpenseRedux,
} from "../../../../actions";
import "./monthly-invoice.css";
import { sendNotifications } from "../../../../firebase/fcmRestApi";
import { getOrderTrackingResult } from "../../../../firebase/firebase.utils";
export class MonthlyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBill: "",
      agent: "",
      totalRmb: "",
      sellRate: "",
      buyRate: "",
      shipping: "",
      productLink: "",
      productName: "",
      totalPaid: 0,
      purchasingObj: null,
      type: "",
      buyingCost: 0,
      finalProfit: 0,
      customer: "",
      customerUid: "",
      cursor: -1,
      purchasingStatus: "",
      productDetails: "",
      country: "",
      filterCountry: "",
      trackingNo: "",
      shippingInformation: null,
      tkRefund: 0,
      tkRefundNote: "",
      rmbRefund: 0,
      rmbRefundNote: "",
      refundType: "",
      cashOutType: "Invoice Payment",
      otherCharge: 0,
      otherChargeNote: "",
    };
  }

  componentDidMount = async () => {
    this.props.getAllPurchasingsRedux(this.props.match.params.month);
    this.props.getAllEmployeeRedux();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };
  handleKeyDown = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.customer) {
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      result = [...suggestionById, ...suggestionByName].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customer: `${result[cursor].userId}-${result[cursor].displayName}`,
          customerUid: result[cursor].uid,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };
  renderShowSuggestion = () => {
    let suggestionArray = [];
    console.log(this.state.customer);
    if (this.state.customer) {
      console.log(this.state.customer);
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      suggestionArray = [...suggestionById, ...suggestionByName];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.userId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customer: `${user.userId}-${user.displayName}`,
              customerUid: user.uid,
              showSuggestion: false,
              subCategory: `${user.userId}-${user.displayName}`,
            })
          }
        >
          {user.userId}-{user.displayName ? user.displayName.slice(0, 13) : ""}
        </li>
      ));
    }
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleSubmit = async (purchasing) => {
    let date = new Date();
    if (this.state.customerUid === "") {
      alert("Please select a customer first");
      return;
    }
    if (this.state.productName === "" || !this.state.productName) {
      alert("Please enter product name");
      return;
    }
    if (this.state.type === "upload") {
      await this.props.uploadPurchasingRedux({
        ...this.state,
        id: date.getTime().toString(),
        month: this.getMonthName(),
        date: date.toLocaleDateString("en-GB"),
        status: "pending",
        totalBill: parseInt(
          parseFloat(this.state.sellRate || 0) *
            parseFloat(this.state.totalRmb || 0) +
            parseInt(this.state.shipping || 0)
        ),
        category: "purchasing",
        invoiceStatus: "Not Paid",
        purchasingStatus: "Pending",
      });

      const message = {
        title: `Purchasing Invoice`,
        body: `A new Purchasing invoice has been added to your account.Please check the information.`,
      };
      const userObj = this.props.allUsers.find(
        (user) => user.uid == this.state.customerUid
      );
      console.log(userObj);
      if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
        userObj.deviceToken.map((token) => {
          sendNotifications(token, message);
        });
      }
    } else if (this.state.type === "update") {
      let purchasingObj = {
        ...this.state,
        id: purchasing.id,
        month: purchasing.month,
        date: purchasing.date,
        status: "pending",
        purchasingObj: null,
        totalBill: parseInt(
          parseFloat(this.state.sellRate || 0) *
            parseFloat(this.state.totalRmb || 0) +
            parseInt(this.state.shipping || 0)
        ),
      };
      await this.props.updatePurchasingRedux(purchasingObj);
    }

    this.setState({
      totalBill: "",
      agent: "",
      totalRmb: "",
      sellRate: "",
      buyRate: "",
      productLink: "",
      productName: "",
      shipping: "",
      totalPaid: 0,
      purchasingObj: null,
      type: "",
      buyingCost: 0,
      finalProfit: 0,
      customer: "",
      customerUid: "",
      cursor: -1,
      country: "",
    });
  };

  handleCashIn = async () => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (!this.state.totalPaid || this.state.totalPaid == 0) {
      alert("Please enter an amount first");
      return;
    }
    let remainingBalance2 = parseFloat(this.state.purchasingObj.totalBill);
    if (this.state.purchasingObj) {
      remainingBalance2 = parseFloat(this.state.purchasingObj.totalBill);
      let receivedCash = 0;
      if (
        this.state.purchasingObj.cashIns &&
        this.state.purchasingObj.cashIns.length > 0
      ) {
        this.state.purchasingObj.cashIns.map((cashIn) => {
          receivedCash += parseInt(cashIn.amount);
        });
        remainingBalance2 =
          parseFloat(this.state.purchasingObj.totalBill) - receivedCash;
      }
    }

    if (this.state.totalPaid > parseInt(remainingBalance2)) {
      alert("Received amount must be less than orders remaining due.");
      return;
    }
    this.props.handlePurchasingCashInRedux({
      ...this.state.purchasingObj,
      totalPaid: this.state.totalPaid,
      paymentId: date.getTime().toString(),
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      receiveBy: currentAdmin.name,
      cashIns:
        this.state.purchasingObj.cashIns &&
        this.state.purchasingObj.cashIns.length > 0
          ? [
              {
                id: date.getTime().toString(),
                amount: this.state.totalPaid,
                date: date.toLocaleDateString("en-GB"),
                expenseBy: currentAdmin.name,
              },
              ...this.state.purchasingObj.cashIns,
            ]
          : [
              {
                id: date.getTime().toString(),
                amount: this.state.totalPaid,
                date: date.toLocaleDateString("en-GB"),
                expenseBy: currentAdmin.name,
              },
            ],
    });
    this.setState({
      totalPaid: 0,
    });
  };

  handleStatusChange = async () => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (!this.state.purchasingStatus) {
      alert("Please select a status first");
      return;
    }
    let purchasingDate;
    let purchasedDate;
    let abroadWarehouseDate;
    let InShipmentDate;
    let bangladeshWarehouseDate;
    let deliveredDate;
    if (this.state.purchasingStatus === "Purchasing") {
      purchasingDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
        tkRefund: this.state.purchasingObj.tkRefund
          ? parseInt(this.state.purchasingObj.tkRefund) +
            parseInt(this.state.tkRefund)
          : parseInt(this.state.tkRefund),
        tkRefundNote: this.state.tkRefundNote,
        rmbRefund: this.state.rmbRefund,
        rmbRefundNote: this.state.rmbRefundNote,
      });
    }
    if (this.state.purchasingStatus == "Purchased") {
      purchasedDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate: this.state.purchasingObj.purchasingDate
          ? this.state.purchasingObj.purchasingDate
          : date.toLocaleDateString("en-GB"),
        purchasedDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
        tkRefund: this.state.tkRefund,
        tkRefundNote: this.state.tkRefundNote,
        rmbRefund: this.state.rmbRefund,
        rmbRefundNote: this.state.rmbRefundNote,
      });
    }
    if (this.state.purchasingStatus == "Abroad Warehouse") {
      abroadWarehouseDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate: this.state.purchasingObj.purchasingDate
          ? this.state.purchasingObj.purchasingDate
          : date.toLocaleDateString("en-GB"),
        purchasedDate: this.state.purchasingObj.purchasedDate
          ? this.state.purchasingObj.purchasedDate
          : date.toLocaleDateString("en-GB"),
        abroadWarehouseDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
        tkRefund: this.state.tkRefund,
        tkRefundNote: this.state.tkRefundNote,
        rmbRefund: this.state.rmbRefund,
        rmbRefundNote: this.state.rmbRefundNote,
      });
    }
    if (this.state.purchasingStatus == "In Shipment") {
      InShipmentDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate: this.state.purchasingObj.purchasingDate
          ? this.state.purchasingObj.purchasingDate
          : date.toLocaleDateString("en-GB"),
        purchasedDate: this.state.purchasingObj.purchasedDate
          ? this.state.purchasingObj.purchasedDate
          : date.toLocaleDateString("en-GB"),
        abroadWarehouseDate: this.state.purchasingObj.abroadWarehouseDate
          ? this.state.purchasingObj.abroadWarehouseDate
          : date.toLocaleDateString("en-GB"),
        InShipmentDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
      });
    }
    if (this.state.purchasingStatus == "Bangladesh Warehouse") {
      bangladeshWarehouseDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate: this.state.purchasingObj.purchasingDate
          ? this.state.purchasingObj.purchasingDate
          : date.toLocaleDateString("en-GB"),
        purchasedDate: this.state.purchasingObj.purchasedDate
          ? this.state.purchasingObj.purchasedDate
          : date.toLocaleDateString("en-GB"),
        abroadWarehouseDate: this.state.purchasingObj.abroadWarehouseDate
          ? this.state.purchasingObj.abroadWarehouseDate
          : date.toLocaleDateString("en-GB"),
        InShipmentDate: this.state.purchasingObj.InShipmentDate
          ? this.state.purchasingObj.InShipmentDate
          : date.toLocaleDateString("en-GB"),
        bangladeshWarehouseDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
      });
    }
    if (this.state.purchasingStatus == "Delivered") {
      deliveredDate = date.toLocaleDateString("en-GB");
      this.props.updatePurchasingRedux({
        ...this.state.purchasingObj,
        purchasingDate: this.state.purchasingObj.purchasingDate
          ? this.state.purchasingObj.purchasingDate
          : date.toLocaleDateString("en-GB"),
        purchasedDate: this.state.purchasingObj.purchasedDate
          ? this.state.purchasingObj.purchasedDate
          : date.toLocaleDateString("en-GB"),
        abroadWarehouseDate: this.state.purchasingObj.abroadWarehouseDate
          ? this.state.purchasingObj.abroadWarehouseDate
          : date.toLocaleDateString("en-GB"),
        InShipmentDate: this.state.purchasingObj.InShipmentDate
          ? this.state.purchasingObj.InShipmentDate
          : date.toLocaleDateString("en-GB"),
        bangladeshWarehouseDate: this.state.purchasingObj
          .bangladeshWarehouseDate
          ? this.state.purchasingObj.bangladeshWarehouseDate
          : date.toLocaleDateString("en-GB"),
        deliveredDate,
        purchasingStatus: this.state.purchasingStatus,
        trackingNo: this.state.trackingNo,
      });
    }

    if (this.state.trackingNo) {
      const message = {
        title: `Purchasing Tracking no added.`,
        body: `Now you can track your purchasing request Id:${this.state.purchasingObj.id} with tracking no:${this.state.trackingNo}.`,
      };
      const userObj = this.props.allUsers.find(
        (user) => user.uid == this.state.purchasingObj.customerUid
      );
      console.log(userObj);
      if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
        userObj.deviceToken.map((token) => {
          sendNotifications(token, message);
        });
      }
    }

    this.setState({
      totalPaid: 0,
      purchasingStatus: "",
    });
  };
  handleRefund = async (totalRefundGiven) => {
    const { currentAdmin, allEmployees } = this.props;
    const { purchasingObj } = this.state;
    let date = new Date();
    if (!this.state.refundType) {
      alert("Please a refund type first.");
      return;
    }
    console.log(
      this.state.refundType,
      this.state.rmbRefund,
      this.state.rmbRefundNote
    );
    let selectedEmployee = allEmployees.find(
      (employee) => employee.adminId == currentAdmin.adminId
    );
    if (
      this.state.refundType == "RECEIVED" &&
      this.state.rmbRefund != 0 &&
      this.state.rmbRefundNote &&
      selectedEmployee
    ) {
      this.props.updatePurchasingRedux({
        ...purchasingObj,
        status: "approved",
        receivedRefund:
          purchasingObj.receivedRefund &&
          purchasingObj.receivedRefund.length > 0
            ? [
                ...purchasingObj.receivedRefund,
                {
                  id: date.getTime().toString(),
                  amount: this.state.rmbRefund,
                  date: date.toLocaleDateString("en-GB"),
                  note: this.state.rmbRefundNote,
                  expenseBy: currentAdmin.name,
                },
              ]
            : [
                {
                  id: date.getTime().toString(),
                  amount: this.state.rmbRefund,
                  date: date.toLocaleDateString("en-GB"),
                  note: this.state.rmbRefundNote,
                  expenseBy: currentAdmin.name,
                },
              ],
      });
      if (this.state.purchasingObj.country == "CHINA") {
        let rmbBalance =
          parseFloat(selectedEmployee.rmbBalance) +
          parseFloat(this.state.rmbRefund);
        let rupeeBalance = parseFloat(selectedEmployee.rupeeBalance);
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
        this.props.uploadTransactionRedux({
          id: date.getTime().toString(),
          fundType: `RMB`,
          subCategory: `Purchasing Refund Received, Invoice: ${this.state.purchasingObj.id}, Date ${this.state.purchasingObj.date}`,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          note: `Buy Rate = ${this.state.purchasingObj.buyRate}`,
          buyRate: this.state.purchasingObj.buyRate,
          amount: this.state.rmbRefund * this.state.purchasingObj.buyRate,
          totalRmb: this.state.rmbRefund,
          expenseBy: currentAdmin.name,
          uid: this.state.customerUid,
          unEditable: true,
          employeeId: selectedEmployee.employeeId,
          rmbBalance,
          rupeeBalance,
        });
      }
      if (this.state.purchasingObj.country == "INDIA") {
        let rupeeBalance =
          parseFloat(selectedEmployee.rupeeBalance) +
          parseFloat(this.state.rmbRefund);
        let rmbBalance = parseFloat(selectedEmployee.rmbBalance);
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
        this.props.uploadTransactionRedux({
          id: date.getTime().toString(),
          fundType: `RUPEES`,
          subCategory: `Purchasing Refund Received, Invoice: ${this.state.purchasingObj.id}, Date ${this.state.purchasingObj.date}`,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          note: `Buy Rate = ${this.state.purchasingObj.buyRate}`,
          buyRate: this.state.purchasingObj.buyRate,
          amount: this.state.rmbRefund * this.state.purchasingObj.buyRate,
          totalRmb: this.state.rmbRefund,
          expenseBy: currentAdmin.name,
          uid: this.state.customerUid,
          unEditable: true,
          employeeId: selectedEmployee.employeeId,
          rmbBalance,
          rupeeBalance,
        });
      }
    } else if (
      this.state.refundType == "GIVEN" &&
      this.state.tkRefund != 0 &&
      this.state.tkRefundNote
    ) {
      console.log(this.state.tkRefund);
      console.log(parseInt(totalRefundGiven));
      console.log(purchasingObj.totalBill);
      console.log(
        parseInt(this.state.tkRefund) + parseInt(totalRefundGiven) >
          parseInt(purchasingObj.totalBill)
      );
      if (
        parseInt(this.state.tkRefund) + parseInt(totalRefundGiven) >
        parseInt(purchasingObj.totalBill)
      ) {
        alert("Refund amount can't be more than total bill");
        return;
      }

      this.props.updatePurchasingRedux({
        ...purchasingObj,
        status: "approved",
        givenRefund:
          purchasingObj.givenRefund && purchasingObj.givenRefund.length > 0
            ? [
                ...purchasingObj.givenRefund,
                {
                  id: date.getTime().toString(),
                  amount: this.state.tkRefund,
                  date: date.toLocaleDateString("en-GB"),
                  note: this.state.tkRefundNote,
                  expenseBy: currentAdmin.name,
                },
              ]
            : [
                {
                  id: date.getTime().toString(),
                  amount: this.state.tkRefund,
                  date: date.toLocaleDateString("en-GB"),
                  note: this.state.tkRefundNote,
                  expenseBy: currentAdmin.name,
                },
              ],
      });

      this.props.uploadExpenseRedux({
        amount: parseInt(this.state.tkRefund),
        category: "Purchasing",
        subCategory: `Agent - ${purchasingObj.agent}`,
        note: `Refund given, Invoice:${purchasingObj.id}
          Date: ${purchasingObj.date}, Total Bill:${purchasingObj.totallBill}RMB`,
        date: date.toLocaleDateString("en-GB"),
        id: date.getTime().toString(),
        month: this.getMonthName(),
        receiveBy: currentAdmin.name,
        status: "pending",
        unEditable: true,
      });
    } else {
      alert("Please fill all missing information.");
      return;
    }

    this.setState({
      refundType: "",
      rmbRefund: 0,
      rmbRefundNote: "",
      tkRefund: 0,
      tkRefundNote: "",
    });
  };

  handleCashOut = async () => {
    const { currentAdmin, allEmployees } = this.props;
    let selectedEmployee = allEmployees.find(
      (employee) => employee.adminId == currentAdmin.adminId
    );
    let date = new Date();
    let remainingBalance = parseFloat(this.state.purchasingObj.totalRmb);
    if (this.state.purchasingObj) {
      remainingBalance = parseFloat(this.state.purchasingObj.totalRmb);
      let spentCash = 0;
      if (
        this.state.purchasingObj.cashOuts &&
        this.state.purchasingObj.cashOuts.length > 0
      ) {
        this.state.purchasingObj.cashOuts.map((cashOut) => {
          spentCash += parseInt(cashOut.rmb);
        });
        remainingBalance =
          parseFloat(this.state.purchasingObj.totalRmb) - spentCash;
      }
    }
    if (!selectedEmployee) {
      alert("Sorry you are not assigned to spend.");
      return;
    }
    if (!this.state.buyingCost || this.state.buyingCost == 0) {
      alert("Please enter an amount first");
      return;
    }
    console.log(remainingBalance);
    console.log(this.state.buyingCost);
    if (parseFloat(this.state.buyingCost) > parseFloat(remainingBalance)) {
      alert("Your spend amount must be less than your remaining payment.");
      return;
    }

    // for india
    if (this.state.purchasingObj.country == "INDIA") {
      if (
        parseFloat(selectedEmployee.rupeeBalance) >=
        parseFloat(this.state.buyingCost)
      ) {
        let rupeeBalance =
          parseFloat(selectedEmployee.rupeeBalance) -
          parseFloat(this.state.buyingCost);
        let rmbBalance = parseFloat(selectedEmployee.rmbBalance);
        this.props.handlePurchasingCashOutRedux({
          ...this.state.purchasingObj,
          buyingCost: this.state.purchasingObj.buyingCost
            ? parseInt(this.state.purchasingObj.buyingCost) +
              parseInt(this.state.buyingCost * this.state.purchasingObj.buyRate)
            : parseInt(
                this.state.buyingCost * this.state.purchasingObj.buyRate
              ),
          paymentId: date.getTime().toString(),
          // buyingcost2 is important for transactions
          buyingcost2: parseFloat(this.state.buyingCost).toFixed(2),
          employeeId: selectedEmployee.employeeId,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          rmbBalance,
          fundType: "RUPEES",
          rupeeBalance,
          expenseBy: currentAdmin.name,
          cashOuts:
            this.state.purchasingObj.cashOuts &&
            this.state.purchasingObj.cashOuts.length > 0
              ? [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.buyingCost * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.buyingCost,
                    expenseBy: currentAdmin.name,
                  },
                  ...this.state.purchasingObj.cashOuts,
                ]
              : [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.buyingCost * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.buyingCost,
                    expenseBy: currentAdmin.name,
                  },
                ],
        });
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
      } else {
        alert("You don't have sufficient balance for this payment.");
      }
    }
    // for china
    else if (this.state.purchasingObj.country == "CHINA") {
      if (
        parseFloat(selectedEmployee.rmbBalance) >=
        parseFloat(this.state.buyingCost)
      ) {
        let rmbBalance =
          parseFloat(selectedEmployee.rmbBalance) -
          parseFloat(this.state.buyingCost);
        let rupeeBalance = parseFloat(selectedEmployee.rupeeBalance);
        this.props.handlePurchasingCashOutRedux({
          ...this.state.purchasingObj,
          buyingCost: this.state.purchasingObj.buyingCost
            ? parseInt(this.state.purchasingObj.buyingCost) +
              parseInt(this.state.buyingCost * this.state.purchasingObj.buyRate)
            : parseInt(
                this.state.buyingCost * this.state.purchasingObj.buyRate
              ),
          paymentId: date.getTime().toString(),
          // buyingcost2 is important for transactions
          buyingcost2: parseFloat(this.state.buyingCost).toFixed(2),
          employeeId: selectedEmployee.employeeId,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          fundType: "RMB",
          rmbBalance,
          rupeeBalance,
          expenseBy: currentAdmin.name,
          cashOuts:
            this.state.purchasingObj.cashOuts &&
            this.state.purchasingObj.cashOuts.length > 0
              ? [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.buyingCost * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.buyingCost,
                    expenseBy: currentAdmin.name,
                  },
                  ...this.state.purchasingObj.cashOuts,
                ]
              : [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.buyingCost * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.buyingCost,
                    expenseBy: currentAdmin.name,
                  },
                ],
        });
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
      } else {
        alert("You don't have sufficient balance for this payment.");
      }
    } else {
      alert("No country is assigned with this order. ");
    }

    this.setState({
      buyingCost: 0,
      cashOutType: "Invoice Payment",
    });
  };
  handleCashOutOtherCharge = async () => {
    const { currentAdmin, allEmployees } = this.props;
    let selectedEmployee = allEmployees.find(
      (employee) => employee.adminId == currentAdmin.adminId
    );
    let date = new Date();

    if (!selectedEmployee) {
      alert("Sorry you are not assigned to spend.");
      return;
    }
    if (!this.state.otherCharge || this.state.otherCharge == 0) {
      alert("Please enter an amount first");
      return;
    }
    if (!this.state.otherChargeNote) {
      alert("You must write a note about other charge.");
      return;
    }

    // for india
    if (this.state.purchasingObj.country == "INDIA") {
      if (
        parseFloat(selectedEmployee.rupeeBalance) >=
        parseFloat(this.state.otherCharge)
      ) {
        let rupeeBalance =
          parseFloat(selectedEmployee.rupeeBalance) -
          parseFloat(this.state.otherCharge);
        let rmbBalance = parseFloat(selectedEmployee.rmbBalance);
        this.props.handlePurchasingCashOutRedux({
          ...this.state.purchasingObj,
          paymentId: date.getTime().toString(),
          // buyingcost2 is important for transactions
          buyingcost2: parseFloat(this.state.otherCharge).toFixed(2),
          employeeId: selectedEmployee.employeeId,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          rmbBalance,
          fundType: "RUPEES",
          rupeeBalance,
          expenseBy: currentAdmin.name,
          otherCharges:
            this.state.purchasingObj.otherCharges &&
            this.state.purchasingObj.otherCharges.length > 0
              ? [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.otherCharge * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.otherCharge,
                    note: this.state.otherChargeNote,
                    expenseBy: currentAdmin.name,
                  },
                  ...this.state.purchasingObj.otherCharges,
                ]
              : [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.otherCharge * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.otherCharge,
                    note: this.state.otherChargeNote,
                    expenseBy: currentAdmin.name,
                  },
                ],
        });
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
      } else {
        alert("You don't have sufficient balance for this payment.");
      }
    }
    // for china
    else if (this.state.purchasingObj.country == "CHINA") {
      if (
        parseFloat(selectedEmployee.rmbBalance) >=
        parseFloat(this.state.otherCharge)
      ) {
        let rmbBalance =
          parseFloat(selectedEmployee.rmbBalance) -
          parseFloat(this.state.otherCharge);
        let rupeeBalance = parseFloat(selectedEmployee.rupeeBalance);
        this.props.handlePurchasingCashOutRedux({
          ...this.state.purchasingObj,

          paymentId: date.getTime().toString(),
          // buyingcost2 is important for transactions
          buyingcost2: parseFloat(this.state.otherCharge).toFixed(2),
          employeeId: selectedEmployee.employeeId,
          month: this.getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          fundType: "RMB",
          rmbBalance,
          rupeeBalance,
          expenseBy: currentAdmin.name,
          otherCharges:
            this.state.purchasingObj.otherCharges &&
            this.state.purchasingObj.otherCharges.length > 0
              ? [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.otherCharge * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.otherCharge,
                    note: this.state.otherChargeNote,
                    expenseBy: currentAdmin.name,
                  },
                  ...this.state.purchasingObj.otherCharges,
                ]
              : [
                  {
                    id: date.getTime().toString(),
                    amount: parseInt(
                      this.state.otherCharge * this.state.purchasingObj.buyRate
                    ),
                    date: date.toLocaleDateString("en-GB"),
                    rmb: this.state.otherCharge,
                    note: this.state.otherChargeNote,
                    expenseBy: currentAdmin.name,
                  },
                ],
        });
        this.props.updateEmployeeRedux({
          ...selectedEmployee,
          rmbBalance,
          rupeeBalance,
        });
      } else {
        alert("You don't have sufficient balance for this payment.");
      }
    } else {
      alert("No country is assigned with this order. ");
    }

    this.setState({
      otherCharge: 0,
      otherChargeNote: "",
      cashOutType: "Invoice Payment",
    });
  };

  render() {
    const { open, purchasingObj, shippingInformation } = this.state;
    const { purchasings, allEmployees, currentAdmin } = this.props;
    let selectedEmployee = null;
    if (allEmployees.length && currentAdmin) {
      selectedEmployee = allEmployees.find(
        (employee) => employee.adminId == currentAdmin.adminId
      );
    }
    let remainingBalance = 0;
    if (this.state.purchasingObj) {
      remainingBalance = parseFloat(this.state.purchasingObj.totalRmb);

      let spentCash = 0;
      if (
        this.state.purchasingObj.cashOuts &&
        this.state.purchasingObj.cashOuts.length > 0
      ) {
        this.state.purchasingObj.cashOuts.map((cashOut) => {
          spentCash += parseFloat(cashOut.rmb);
        });
        console.log(spentCash);
        console.log(remainingBalance);
        remainingBalance =
          parseFloat(this.state.purchasingObj.totalRmb) - spentCash;
        console.log(remainingBalance);
      }
    }

    let remainingBalance2 = 0;
    if (this.state.purchasingObj) {
      remainingBalance2 = parseFloat(this.state.purchasingObj.totalBill);
      let receivedCash = 0;
      if (
        this.state.purchasingObj.cashIns &&
        this.state.purchasingObj.cashIns.length > 0
      ) {
        this.state.purchasingObj.cashIns.map((cashIn) => {
          receivedCash += parseInt(cashIn.amount);
        });
        remainingBalance2 =
          parseFloat(this.state.purchasingObj.totalBill) - receivedCash;
      }
    }

    let date = new Date();
    let totalBill = 0;
    let totalRmb = 0;
    let totalPaid = 0;
    let buyingCost = 0;
    let totalProfit = 0;
    let sellRate = 0;
    let buyRate = 0;
    let shipping = 0;
    let totalRefundGiven = 0;
    let totalRefundReceived = 0;
    let totalRefundReceivedTk = 0;
    let totalOtherCharges = 0;
    let totalOtherChargesTk = 0;
    if (
      purchasingObj &&
      purchasingObj.receivedRefund &&
      purchasingObj.receivedRefund.length > 0
    ) {
      purchasingObj.receivedRefund.map((refund) => {
        totalRefundReceived += parseFloat(refund.amount);
      });
      totalRefundReceivedTk = parseInt(
        totalRefundReceived * purchasingObj.buyRate
      );
    }
    if (
      purchasingObj &&
      purchasingObj.otherCharges &&
      purchasingObj.otherCharges.length > 0
    ) {
      purchasingObj.otherCharges.map((refund) => {
        totalOtherCharges += parseFloat(refund.rmb);
      });
      totalOtherChargesTk = parseInt(totalOtherCharges * purchasingObj.buyRate);
    }
    if (
      purchasingObj &&
      purchasingObj.givenRefund &&
      purchasingObj.givenRefund.length > 0
    ) {
      purchasingObj.givenRefund.map((refund) => {
        totalRefundGiven += parseFloat(refund.amount);
      });
    }
    let renderablePurchasings = [];
    if (!this.state.filterCountry) {
      renderablePurchasings = purchasings.filter(
        (purchasing) => purchasing.agent
      );
    } else {
      renderablePurchasings = purchasings
        .filter((purchasing) => purchasing.agent)
        .filter(
          (purchasing) => purchasing.country === this.state.filterCountry
        );
    }
    if (renderablePurchasings.length > 0) {
      renderablePurchasings.map((purchasing) => {
        totalBill += parseInt(purchasing.totalBill || 0);
        totalRmb += parseInt(purchasing.totalRmb || 0);
        totalPaid += parseInt(purchasing.totalPaid || 0);
        buyingCost += parseInt(purchasing.buyingCost || 0);
        totalProfit += parseInt(purchasing.finalProfit || 0);
        sellRate += parseFloat(purchasing.sellRate || 0);
        buyRate += parseFloat(purchasing.buyRate || 0);
        shipping += parseFloat(purchasing.shipping || 0);
      });
    }

    let total = 0;
    if (shippingInformation) {
      shippingInformation.parcelsArray.map(
        (parcel) => (total += parseInt(parcel.finalTotal))
      );
    }
    return (
      <Fragment>
        <Breadcrumb title={"Purchasing"} parent="Business Consultancy" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <>
              <div className="card o-hidden widget-cards">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#00254c" }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        {this.props.match.params.month} Total Purchasing
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp className="counter" end={totalBill} />
                        RMB
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {this.props.match.params.month}
                  </h5>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <select
                        title="Filter by country"
                        required
                        name="filterCountry"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={this.handleChange}
                        value={this.state.filterCountry}
                      >
                        <option value="">Filter by country </option>
                        <option value="">All</option>
                        <option value="INDIA">INDIA</option>
                        <option value="CHINA">CHINA</option>
                      </select>
                    </div>
                    <button
                      style={{ marginLeft: 10 }}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#personalInfoModal"
                      onClick={() => {
                        this.setState({
                          totalBill: "",
                          agent: "",
                          totalRmb: "",
                          sellRate: "",
                          buyRate: "",
                          productLink: "",
                          productName: "",
                          totalPaid: 0,
                          purchasingObj: null,
                          type: "upload",
                          buyingCost: 0,
                          finalProfit: 0,
                          customer: "",
                          customerUid: "",
                          cursor: -1,
                        });
                      }}
                    >
                      New Invoice
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover table-responsive-md">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Customer
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Product Name
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Country
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Sell Rate
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Buy Rate
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Paid
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Bill
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Buying Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Profit
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>
                          {currentAdmin ? (
                            currentAdmin.status === "Admin" ||
                            currentAdmin.status === "Accounts" ||
                            (selectedEmployee &&
                              selectedEmployee.rupeeBalance > 0) ||
                            (selectedEmployee &&
                              selectedEmployee.rmbBalance > 0) ? (
                              <>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Refund
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Cash In
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Cash Out
                                </th>
                              </>
                            ) : null
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {renderablePurchasings.map((purchasing, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {index + 1}
                            </th>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.date}
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.id}
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.agent}
                            </td>
                            <td style={{ color: "#ff8084" }}>
                              <Link
                                to={`/users/list-user/${purchasing.customerUid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {purchasing.customer}
                              </Link>
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.productName}
                            </td>

                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.country}
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.totalRmb || 0}
                              {purchasing.country == "INDIA"
                                ? " rupees"
                                : " rmb"}
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.sellRate || 0}RMB
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.buyRate || 0}RMB
                            </td>

                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.totalPaid || 0}RMB
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.totalBill || 0}RMB
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.buyingCost || 0}RMB
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push(
                                  `/business-consultancy/purchasing2/${purchasing.id}`
                                );
                              }}
                            >
                              {purchasing.finalProfit || 0}RMB
                            </td>

                            {purchasing.status === "pending" ? (
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#personalInfoModal"
                                    onClick={() => {
                                      this.setState({
                                        purchasingObj: purchasing,
                                        totalBill: purchasing.totalBill,
                                        totalPaid: 0,
                                        agent: purchasing.agent,
                                        productLink: purchasing.productLink,
                                        productName: purchasing.productName,
                                        productDetails:
                                          purchasing.productDetails,
                                        buyingCost: 0,
                                        sellRate: purchasing.sellRate || 0,
                                        buyRate: purchasing.buyRate,
                                        totalRmb: purchasing.totalRmb || 0,
                                        finalProfit: 0,
                                        status: "pending",
                                        type: "update",
                                        customer: purchasing.customer,
                                        customerUid: purchasing.customerUid,
                                        cursor: -1,
                                        purchasingStatus:
                                          purchasing.purchasingStatus,

                                        country: purchasing.country,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal"
                                    onClick={() => {
                                      this.setState({
                                        purchasingObj: purchasing,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td>
                              <span
                                style={{
                                  padding: "3px 5px",
                                  background:
                                    purchasing.purchasingStatus ==
                                      "Purchasing" ||
                                    purchasing.purchasingStatus == "Purchased"
                                      ? "#f9ac21"
                                      : purchasing.purchasingStatus ==
                                          "Delivered" ||
                                        purchasing.purchasingStatus ==
                                          "Bangladesh Warehouse"
                                      ? "green"
                                      : purchasing.purchasingStatus
                                      ? "cadetblue"
                                      : "red",
                                  color: "white",
                                  borderRadius: "5px",
                                  fontSize: 12,
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#StatusModal"
                                onClick={async () => {
                                  this.setState({
                                    purchasingObj: purchasing,
                                    purchasingStatus:
                                      purchasing.purchasingStatus
                                        ? purchasing.purchasingStatus
                                        : "",
                                    trackingNo: purchasing.trackingNo
                                      ? purchasing.trackingNo
                                      : "",
                                  });
                                }}
                              >
                                {purchasing.purchasingStatus || "Update"}
                              </span>
                            </td>
                            {currentAdmin ? (
                              currentAdmin.status === "Admin" ||
                              currentAdmin.status === "Accounts" ||
                              (selectedEmployee &&
                                selectedEmployee.rupeeBalance > 0) ||
                              (selectedEmployee &&
                                selectedEmployee.rmbBalance > 0) ? (
                                <>
                                  <td>
                                    <span
                                      style={{
                                        color: "white",
                                        padding: "3px 5px",
                                        borderRadius: 5,
                                        backgroundColor: "#2196F3",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#StatusModal1"
                                      onClick={() => {
                                        this.setState({
                                          purchasingObj: purchasing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" />{" "}
                                      refund
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        color: "white",
                                        padding: "3px 5px",
                                        borderRadius: 5,
                                        backgroundColor: "darkgreen",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#InvoiceInfoModal1"
                                      onClick={() => {
                                        this.setState({
                                          purchasingObj: purchasing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" />{" "}
                                      receive
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        color: "white",
                                        padding: "3px 5px",
                                        borderRadius: 5,
                                        backgroundColor: "darkorange",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#InvoiceInfoModal2"
                                      onClick={() => {
                                        this.setState({
                                          purchasingObj: purchasing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" /> spend
                                    </span>
                                  </td>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                        <tr>
                          <td
                            colSpan={7}
                            style={{ fontWeight: "bold", textAlign: "end" }}
                          >
                            Total
                          </td>
                          <td style={{ fontWeight: "bold" }}>{totalRmb}Rmb</td>
                          <td style={{ fontWeight: "bold" }}>
                            {renderablePurchasings.length > 0
                              ? parseFloat(
                                  sellRate / renderablePurchasings.length
                                ).toFixed(2)
                              : 0}
                            RMB
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {renderablePurchasings.length > 0
                              ? parseFloat(
                                  buyRate / renderablePurchasings.length
                                ).toFixed(2)
                              : 0}
                            RMB
                          </td>

                          <td style={{ fontWeight: "bold" }}>{totalPaid}RMB</td>
                          <td style={{ fontWeight: "bold" }}>{totalBill}RMB</td>
                          <td style={{ fontWeight: "bold" }}>
                            {buyingCost}RMB
                          </td>
                          <td style={{ fontWeight: "bold" }} colSpan={6}>
                            {totalProfit}RMB
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Create Purchasing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PURCHASING AGENT
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="agent"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.agent}
                    >
                      <option value="">SELECT EMPLOYEE</option>
                      {allEmployees.map((employee) => (
                        <option value={`${employee.name}`}>
                          {employee.name}-{employee.designation}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SELECT CUSTOMER
                    </label>
                    <input
                      title="Please choose a package"
                      style={{ padding: 18 }}
                      type="text"
                      name="customer"
                      className="form-control"
                      placeholder="Enter customer Id"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChangeCustomer}
                      value={this.state.customer}
                      required
                      autoComplete="off"
                      onKeyDown={this.handleKeyDown}
                    />
                    {this.state.customer && (
                      <ul
                        className="below-searchbar-recommendation"
                        style={{
                          display: this.state.showSuggestion ? "flex" : "none",
                          zIndex: 11,
                        }}
                      >
                        {this.renderShowSuggestion()}
                      </ul>
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SELECT COUNTRY
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="country"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.country}
                    >
                      <option value="">SELECT COUNTRY</option>
                      <option value="INDIA">INDIA</option>
                      <option value="CHINA">CHINA</option>
                    </select>
                  </div>
                  {this.state.country && (
                    <>
                      <div className="form-group">
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#505050",
                            marginBottom: 5,
                          }}
                        >
                          PRODUCT NAME
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productName"
                          onChange={this.handleChange}
                          value={this.state.productName}
                          id="exampleFormControlInput1"
                          placeholder="Enter product name"
                          style={{
                            borderColor: "gainsboro",
                            borderRadius: 5,
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#505050",
                            marginBottom: 5,
                          }}
                        >
                          TOTAL{" "}
                          {this.state.country == "INDIA" ? "RUPEES" : "RMB"}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="totalRmb"
                          value={this.state.totalRmb}
                          onChange={this.handleChange}
                          id="exampleFormControlInput1"
                          placeholder={`Total ${
                            this.state.country == "INDIA" ? "RUPEES" : "RMB"
                          }`}
                          style={{
                            borderColor: "gainsboro",
                            borderRadius: 5,
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#505050",
                            marginBottom: 5,
                          }}
                        >
                          {this.state.country == "INDIA" ? "RUPEES" : "RMB"}{" "}
                          SELL RATE
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="sellRate"
                          value={this.state.sellRate}
                          onChange={this.handleChange}
                          id="exampleFormControlInput1"
                          placeholder="sell rate"
                          style={{
                            borderColor: "gainsboro",
                            borderRadius: 5,
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#505050",
                            marginBottom: 5,
                          }}
                        >
                          {this.state.country == "INDIA" ? "RUPEES" : "RMB"} BUY
                          RATE
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="buyRate"
                          value={this.state.buyRate}
                          onChange={this.handleChange}
                          id="exampleFormControlInput1"
                          placeholder="buy rate"
                          style={{
                            borderColor: "gainsboro",
                            borderRadius: 5,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit(purchasingObj);
                  }}
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Purchasing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Purchasing?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{purchasingObj && purchasingObj.agent}</td>
                      <td>{purchasingObj && purchasingObj.customer}</td>
                      <td>
                        {(purchasingObj && purchasingObj.totalRmb) || 0}Rmb
                      </td>
                      <td>
                        {(purchasingObj && purchasingObj.sellRate) || 0}Tk/Rmb
                      </td>
                      <td>{purchasingObj && purchasingObj.totalBill}RMB</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deletePurchasingRedux(purchasingObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="InvoiceInfoModal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Receive Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" style={{ marginBottom: 30 }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                    }}
                  >
                    ORDER DETAILS
                  </label>
                  <div>
                    Order Due:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {remainingBalance2.toFixed(2)} RMB
                    </span>
                  </div>
                </div>
                <div style={{ marginBottom: 5 }}>
                  <div style={{ fontWeight: "bold" }}>
                    Invoice Id:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{purchasingObj && purchasingObj.agent}</td>
                      <td>{purchasingObj && purchasingObj.customer}</td>
                      <td>
                        {(purchasingObj && purchasingObj.totalRmb) || 0}{" "}
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " Rupees"
                          : " Rmb"}
                      </td>
                      <td>
                        {(purchasingObj && purchasingObj.sellRate) || 0}Tk/{" "}
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " Rupees"
                          : " Rmb"}
                      </td>
                      <td>
                        Total-{purchasingObj && purchasingObj.totalBill}RMB
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                      marginBottom: 5,
                    }}
                  >
                    RECEIVED CASH
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="totalPaid"
                    value={this.state.totalPaid}
                    onChange={this.handleChange}
                    id="exampleFormControlInput1"
                    placeholder="Enter amount"
                    style={{
                      borderColor: "gainsboro",
                      borderRadius: 5,
                    }}
                  />
                  <span style={{ marginTop: 5, color: "gray", fontSize: 14 }}>
                    ***Received cash amount must be less than or equal to order
                    due.
                  </span>
                </div>
                {purchasingObj &&
                  purchasingObj.cashIns &&
                  purchasingObj.cashIns.length > 0 && (
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        PREVIOUS CASH INS
                      </label>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr style={{ background: "rgb(0, 37, 76)" }}>
                            <th style={{ color: "white" }}>Date</th>
                            <th style={{ color: "white" }}>Amount</th>
                            <th style={{ color: "white" }}>Entry By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchasingObj.cashIns
                            .sort((a, b) => a.id - b.id)
                            .map((refund) => (
                              <tr>
                                <td>{refund.date}</td>
                                <td>{refund.amount} RMB</td>
                                <td>{refund.expenseBy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                {remainingBalance2 <= 0 ? (
                  <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "darkgreen",
                      color: "white",
                      padding: 8,
                      borderRadius: 5,
                    }}
                  >
                    Paid
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "darkgreen",
                      color: "white",
                      padding: 8,
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      this.handleCashIn(purchasingObj);
                    }}
                  >
                    Cash In
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="StatusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Purchasing Status
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Invoice Id:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Status{" "}
                  <span
                    style={{
                      padding: "3px 5px",
                      background: purchasingObj
                        ? purchasingObj.purchasingStatus == "Purchasing" ||
                          purchasingObj.purchasingStatus == "Purchased"
                          ? "#f9ac21"
                          : purchasingObj.purchasingStatus == "Delivered" ||
                            purchasingObj.purchasingStatus ==
                              "Bangladesh Warehouse"
                          ? "green"
                          : purchasingObj.purchasingStatus
                          ? "cadetblue"
                          : "red"
                        : "red",
                      color: "white",
                      borderRadius: "5px",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {purchasingObj && purchasingObj.purchasingStatus}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Order Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.purchasingDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Purchased Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.purchasedDate}{" "}
                  </span>
                </div>

                <div
                  style={{
                    padding: "2px 0px",
                    borderBottom: "1px solid gainsboro",
                    marginBottom: 20,
                  }}
                ></div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        UPDATE STATUS
                      </label>
                      <select
                        title="Please choose a package"
                        required
                        name="purchasingStatus"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={this.handleChange}
                        value={this.state.purchasingStatus}
                      >
                        <option value="">SELECT STATUS</option>
                        <option value="Purchasing">Purchasing</option>
                        <option value="Purchased">Purchased</option>
                        <option value="Abroad Warehouse">
                          Abroad Warehouse
                        </option>
                        <option value="In Shipment">In Shipment</option>
                        <option value="Bangladesh Warehouse">
                          Bangladesh Warehouse
                        </option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleStatusChange(purchasingObj);
                  }}
                >
                  Update Status
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="StatusModal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Order summary
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Invoice Id:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total{" "}
                  {purchasingObj && purchasingObj.country == "INDIA"
                    ? " rupees"
                    : " rmb"}
                  :
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {purchasingObj && purchasingObj.totalRmb}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Buy Rate:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.buyRate} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Sell Rate:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.sellRate} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total Bill:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.totalBill} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total Paid:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {(purchasingObj && purchasingObj.totalPaid) || 0} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Buying Cost:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {(purchasingObj && purchasingObj.buyingCost) || 0} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total Refund given:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {totalRefundGiven} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total Refund Received:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {totalRefundReceived}{" "}
                    {purchasingObj && purchasingObj.country == "INDIA"
                      ? "rupees"
                      : "rmb"}{" "}
                    * {purchasingObj && purchasingObj.buyRate} ={" "}
                    {totalRefundReceivedTk} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Total Other charges:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {totalOtherCharges}{" "}
                    {purchasingObj && purchasingObj.country == "INDIA"
                      ? "rupees"
                      : "rmb"}{" "}
                    * {purchasingObj && purchasingObj.buyRate} ={" "}
                    {totalOtherChargesTk} RMB
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Profit:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj &&
                      purchasingObj.finalProfit +
                        totalRefundReceivedTk -
                        totalRefundGiven -
                        totalOtherChargesTk}{" "}
                    RMB
                  </span>
                </div>

                <div
                  style={{
                    padding: "2px 0px",
                    borderBottom: "1px solid gainsboro",
                    marginBottom: 20,
                  }}
                ></div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        REFUND TYPE
                      </label>
                      <select
                        title="Please choose a package"
                        required
                        name="refundType"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={this.handleChange}
                        value={this.state.refundType}
                      >
                        <option value="">SELECT TYPE</option>
                        <option value="RECEIVED">RECEIVED</option>
                        <option value="GIVEN">GIVEN</option>
                      </select>
                    </div>
                  </div>
                </div>
                {this.state.refundType == "GIVEN" && (
                  <>
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        Refund Given to customer in Tk
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="tkRefund"
                        value={this.state.tkRefund}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Enter the amount in tk"
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        Taka Refund Note
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="tkRefundNote"
                        value={this.state.tkRefundNote}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Enter short note about why the refund is given to customer."
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                  </>
                )}
                {this.state.refundType == "RECEIVED" && (
                  <>
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        {purchasingObj && purchasingObj.country === "INDIA"
                          ? "Rupees"
                          : "Rmb"}{" "}
                        Refund received
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="rmbRefund"
                        value={this.state.rmbRefund}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Enter the amount in rupees/rmb"
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        {purchasingObj && purchasingObj.country === "INDIA"
                          ? "Rupees"
                          : "Rmb"}{" "}
                        Refund Note
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="rmbRefundNote"
                        value={this.state.rmbRefundNote}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Enter short note about why the refund is given to us by the supplier."
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                  </>
                )}

                {purchasingObj &&
                  purchasingObj.givenRefund &&
                  purchasingObj.givenRefund.length > 0 && (
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        PREVIOUS REFUND GIVEN
                      </label>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr style={{ background: "rgb(0, 37, 76)" }}>
                            <th style={{ color: "white" }}>Date</th>

                            <th colSpan={3} style={{ color: "white" }}>
                              Note
                            </th>
                            <th style={{ color: "white" }}>Amount</th>
                            <th style={{ color: "white" }}>Entry By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchasingObj.givenRefund
                            .sort((a, b) => a.id - b.id)
                            .map((refund) => (
                              <tr>
                                <td>{refund.date}</td>
                                <td colSpan={3}>{refund.note}</td>
                                <td>{refund.amount} RMB</td>
                                <td>{refund.expenseBy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {purchasingObj &&
                  purchasingObj.receivedRefund &&
                  purchasingObj.receivedRefund.length > 0 && (
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        PREVIOUS REFUND RECEIVED
                      </label>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr style={{ background: "rgb(0, 37, 76)" }}>
                            <th style={{ color: "white" }}>Date</th>

                            <th colSpan={3} style={{ color: "white" }}>
                              Note
                            </th>
                            <th style={{ color: "white" }}>Amount</th>
                            <th style={{ color: "white" }}>Entry By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchasingObj.receivedRefund
                            .sort((a, b) => a.id - b.id)
                            .map((refund) => (
                              <tr>
                                <td>{refund.date}</td>
                                <td colSpan={3}>{refund.note}</td>
                                <td>
                                  {refund.amount}{" "}
                                  {purchasingObj.country == "INDIA"
                                    ? "Rupees"
                                    : "Rmb"}
                                </td>
                                <td>{refund.expenseBy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleRefund(totalRefundGiven);
                  }}
                >
                  Add New Refund
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="InvoiceInfoModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" style={{ marginBottom: 30 }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                    }}
                  >
                    YOUR BALANCE
                  </label>
                  <div>
                    Rupee Balance:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {selectedEmployee && selectedEmployee.rupeeBalance
                        ? selectedEmployee.rupeeBalance
                        : 0}{" "}
                      Rupees
                    </span>
                  </div>
                  <div>
                    Rmb Balance:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {selectedEmployee && selectedEmployee.rmbBalance
                        ? selectedEmployee.rmbBalance
                        : 0}{" "}
                      Rmb
                    </span>
                  </div>
                  <div>
                    Remaining Payment:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {remainingBalance.toFixed(2)}{" "}
                      {purchasingObj && purchasingObj.country === "INDIA"
                        ? "Rupees"
                        : "Rmb"}
                    </span>
                  </div>
                </div>
                <div style={{ marginBottom: 5 }}>
                  <div style={{ fontWeight: "bold" }}>
                    INVOICE ID:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{purchasingObj && purchasingObj.agent}</td>
                      <td>{purchasingObj && purchasingObj.customer}</td>
                      <td>
                        {purchasingObj && purchasingObj.totalRmb}{" "}
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " Rupees"
                          : " Rmb"}
                      </td>
                      <td>
                        {purchasingObj && purchasingObj.buyRate}Tk/
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " Rupees"
                          : " Rmb"}
                      </td>
                      <td>
                        Total-
                        {purchasingObj &&
                          parseInt(
                            parseFloat(purchasingObj.totalRmb) *
                              parseFloat(purchasingObj.buyRate)
                          )}
                        RMB
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                      marginBottom: 5,
                    }}
                  >
                    SELECT CASHOUT TYPE
                  </label>
                  <select
                    title="Please choose a package"
                    required
                    name="cashOutType"
                    className="custom-select"
                    aria-required="true"
                    aria-invalid="false"
                    onChange={this.handleChange}
                    value={this.state.cashOutType}
                  >
                    <option value="">SELECT SPEND TYPE</option>
                    <option value="Invoice Payment">Invoice Payment</option>
                    <option value="Other charges">Other charges</option>
                  </select>
                </div>
                {this.state.cashOutType == "Other charges" && (
                  <>
                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        OTHER CHARGE NOTE{" "}
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="otherChargeNote"
                        value={this.state.otherChargeNote}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Write short note about why the charge is being added."
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        SPEND{" "}
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " RUPEES"
                          : " RMB"}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="otherCharge"
                        value={this.state.otherCharge}
                        onChange={this.handleChange}
                        id="exampleFormControlInput1"
                        placeholder="Enter amount"
                        style={{
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />
                      <span
                        style={{ marginTop: 5, color: "gray", fontSize: 14 }}
                      >
                        ***Your spend amount must be less than or equal to your{" "}
                        {purchasingObj && purchasingObj.country == "INDIA"
                          ? " RUPEES"
                          : " RMB"}{" "}
                        balance and remaining payment.
                      </span>
                    </div>
                  </>
                )}
                {this.state.cashOutType === "Invoice Payment" && (
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SPEND{" "}
                      {purchasingObj && purchasingObj.country == "INDIA"
                        ? " RUPEES"
                        : " RMB"}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="buyingCost"
                      value={this.state.buyingCost}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Enter amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                    <span style={{ marginTop: 5, color: "gray", fontSize: 14 }}>
                      ***Your spend amount must be less than or equal to your{" "}
                      {purchasingObj && purchasingObj.country == "INDIA"
                        ? " RUPEES"
                        : " RMB"}{" "}
                      balance and remaining payment.
                    </span>
                  </div>
                )}
                {purchasingObj &&
                  purchasingObj.cashOuts &&
                  purchasingObj.cashOuts.length > 0 && (
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        PREVIOUS CASH OUTS
                      </label>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr style={{ background: "rgb(0, 37, 76)" }}>
                            <th style={{ color: "white" }}>Date</th>
                            <th style={{ color: "white" }}>Amount</th>
                            <th style={{ color: "white" }}>
                              {purchasingObj &&
                              purchasingObj.country === "INDIA"
                                ? "Rupees"
                                : "RMB"}
                            </th>
                            <th style={{ color: "white" }}>Entry By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchasingObj.cashOuts
                            .sort((a, b) => a.id - b.id)
                            .map((refund) => (
                              <tr>
                                <td>{refund.date}</td>
                                <td>{refund.amount} RMB</td>
                                <td>
                                  {refund.rmb}{" "}
                                  {purchasingObj &&
                                  purchasingObj.country === "INDIA"
                                    ? "Rupees"
                                    : "RMB"}
                                </td>
                                <td>{refund.expenseBy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {purchasingObj &&
                  purchasingObj.otherCharges &&
                  purchasingObj.otherCharges.length > 0 && (
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#505050",
                          marginBottom: 5,
                        }}
                      >
                        PREVIOUS OTHER CHARGES
                      </label>
                      <table className="table table-bordered table-striped table-hover">
                        <thead>
                          <tr style={{ background: "rgb(0, 37, 76)" }}>
                            <th style={{ color: "white" }}>Date</th>
                            <th colSpan={3} style={{ color: "white" }}>
                              Note
                            </th>
                            <th style={{ color: "white" }}>Amount</th>
                            <th style={{ color: "white" }}>
                              {purchasingObj &&
                              purchasingObj.country === "INDIA"
                                ? "Rupees"
                                : "RMB"}
                            </th>
                            <th style={{ color: "white" }}>Entry By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchasingObj.otherCharges
                            .sort((a, b) => a.id - b.id)
                            .map((refund) => (
                              <tr>
                                <td>{refund.date}</td>
                                <td colSpan={3}>{refund.note}</td>
                                <td>{refund.amount} RMB</td>
                                <td>
                                  {refund.rmb}{" "}
                                  {purchasingObj &&
                                  purchasingObj.country === "INDIA"
                                    ? "Rupees"
                                    : "RMB"}
                                </td>
                                <td>{refund.expenseBy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                {remainingBalance <= 0 &&
                this.state.cashOutType == "Invoice Payment" ? (
                  <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "darkgreen",
                      color: "white",
                      padding: 8,
                      borderRadius: 5,
                    }}
                  >
                    Paid
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      padding: 8,
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      if (this.state.cashOutType == "Other charges") {
                        this.handleCashOutOtherCharge(purchasingObj);
                      } else if (this.state.cashOutType == "Invoice Payment") {
                        this.handleCashOut(purchasingObj);
                      } else {
                        alert("You must select a cashout type.");
                      }
                    }}
                  >
                    Cash Out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allUsers: state.users.users,
    currentAdmin: state.admins.currentAdmin,
    purchasings: state.purchasings.purchasings,
    allEmployees: state.employees.employees.filter(
      (employee) => employee.status == "Active"
    ),
    lots: state.lots.lots,
  };
};

export default connect(mapStateToProps, {
  getAllPurchasingsRedux,
  uploadPurchasingRedux,
  updatePurchasingRedux,
  deletePurchasingRedux,
  getAllEmployeeRedux,
  updateEmployeeRedux,
  uploadTransactionRedux,
  handlePurchasingCashInRedux,
  handlePurchasingCashOutRedux,
  uploadExpenseRedux,
})(MonthlyInvoices);
