import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentSourcingRedux,
  updatePaymentRequestSourcingStatusRedux,
} from "../../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";
import { getAllOrdersByTrackingNo } from "../../../firebase/firebase.utils";
import { sendNotifications } from "../../../firebase/fcmRestApi";
import { CircleLoader } from "react-spinners";
class MakePaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndCondition: false,
      loader: false,
      invoicesToPay: [],
      allOrders: [],
    };
  }

  componentWillReceiveProps = async (nextProps) => {
    console.log("componet will received is getting called outside!");
    const { payableOrders } = nextProps;
    if (payableOrders && payableOrders.length > 0) {
      console.log("componet will received is getting called inside!");

      let invoicesToPayWithShipmentArray = [];
      for (let i = 0; i < payableOrders.length; i++) {
        const invoice = payableOrders[i];
        if (invoice.trackingNo) {
          let allOrders = await getAllOrdersByTrackingNo(invoice.trackingNo);
          invoicesToPayWithShipmentArray.push({
            ...invoice,
            allOrders,
          });
          this.setState({
            invoicesToPay: invoicesToPayWithShipmentArray,
          });
        } else {
          invoicesToPayWithShipmentArray.push({
            ...invoice,
          });
          this.setState({
            invoicesToPay: invoicesToPayWithShipmentArray,
          });
        }
      }
    }
  };

  handleSubmit = async (total) => {
    const { admin, paymentRequestObj, rechargeUser, payableOrders } =
      this.props;

    // admin payment korle admin jabe, user payment korle user jabe
    this.setState({
      loader: true,
    });
    await this.props.makePaymentSourcingRedux(
      total,
      paymentRequestObj.paidInvoices,
      rechargeUser,
      admin,
      payableOrders,
      paymentRequestObj.method
    );
    await this.props.updatePaymentRequestSourcingStatusRedux({
      ...paymentRequestObj,
      status: "recharged",
    });

    const message = {
      title: `Sourcing/Purchasing`,
      body: `Your Payment request Id:${paymentRequestObj.paymentId} is successful. Thank you`,
    };
    const userObj = this.props.allUsers.find(
      (user) => user.uid == paymentRequestObj.userId
    );
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }
    this.setState({
      loader: false,
    });
    toast.success("Payment is successful");
    this.props.startToggleModal(null, []);
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  getAllShipmentDue = (allOrders) => {
    if (allOrders && allOrders.length > 0) {
      let totalDue = 0;
      allOrders.map((order) => {
        let due = order.finalTotal;
        if (order.invoiceStatus == "Paid") {
          due = 0;
        }
        totalDue += parseInt(due);
      });
      return (
        <div
          style={{ color: "#ff8084", fontWeight: "bold", cursor: "pointer" }}
          data-toggle="modal"
          data-target="#detailInfoModal"
          onClick={() => {
            this.setState({
              allOrders: allOrders,
            });
          }}
        >
          {totalDue} RMB
        </div>
      );
    }
    return "0 RMB";
  };

  render() {
    const { payableOrders } = this.props;
    const { invoicesToPay } = this.state;
    console.log(payableOrders);
    let total = 0;

    invoicesToPay.forEach((invoice) => {
      console.log(invoice.finalTotal);
      return (total +=
        (invoice.totalBill ||
          parseInt(invoice.amount) * parseInt(invoice.quantity)) -
        (invoice.totalPaid || 0));
    });

    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ backgroundColor: "white" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => this.props.startToggleModal(null, [])}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2 className="h2-xl mb-2 fw-6 pb-2 order-details-header">
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div
                            className="table-responsive-md"
                            style={{
                              border: "1px solid gainsboro",
                              borderBottom: "none",
                            }}
                          >
                            <table className="table">
                              <thead style={{ position: "sticky", top: -3 }}>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Invoice No
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Category
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Shipment Due
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Bill
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Paid
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Due <br />{" "}
                                    <span style={{ fontSize: 13 }}>
                                      without shipment
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesToPay.length > 0 &&
                                  invoicesToPay.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.parcelId}
                                      >
                                        <th scope="row">
                                          {invoice.id || invoice.paymentId}
                                        </th>
                                        <td>{invoice.date}</td>
                                        <td>{invoice.category}</td>
                                        <td>
                                          {this.getAllShipmentDue(
                                            invoice.allOrders
                                          )}
                                        </td>
                                        <td>
                                          {invoice.totalBill ||
                                            parseInt(invoice.amount) *
                                              parseInt(invoice.quantity)}
                                          RMB
                                        </td>
                                        <td>{invoice.totalPaid || 0}RMB</td>
                                        <td>
                                          {(invoice.totalBill ||
                                            parseInt(invoice.amount) *
                                              parseInt(invoice.quantity)) -
                                            (invoice.totalPaid || 0)}
                                        RMB
                                        </td>
                                      </tr>
                                    );
                                  })}

                                <tr className="table-light">
                                  <td colSpan={5}></td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 24,
                                    }}
                                  >
                                    Grand Total
                                  </td>
                                  <td className="result-td">{total}RMB</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{ display: "flex", flexDirectioin: "row" }}
                          >
                            <input
                              className="terms-condition-checkbox"
                              style={{ marginLeft: 10 }}
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div className="agree-terms-condition">
                              I checked and paying the invoices.
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                &nbsp; For any kind of fault
                              </span>
                              &nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                I will be responsible.
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    this.handleSubmit(total);
                                  }}
                                  className="mt-3 btn btn-secondary "
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_payment_popup"
                                  style={{ minWidth: 120, minHeight: 40 }}
                                >
                                  {!this.state.loader && <>Approve Payment</>}
                                  <CircleLoader
                                    loading={this.state.loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  All Shipment Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Invoice Id</td>
                      <td style={{ fontWeight: "bold" }}>Customer</td>
                      <td style={{ fontWeight: "bold" }}>Shipping Mark</td>
                      <td style={{ fontWeight: "bold" }}>Weight</td>
                      <td style={{ fontWeight: "bold" }}>Rate/Kg</td>
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td style={{ fontWeight: "bold" }}>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allOrders.length > 0 &&
                      this.state.allOrders.map((order) => {
                        return (
                          <tr>
                            <td>{order.parcelId}</td>
                            <td>{order.customer}</td>
                            <td>{order.shippingMark}</td>
                            <td>{order.grossWeight}Kg</td>
                            <td>{order.ratePerKg} RMB</td>
                            <td>{order.finalTotal} RMB</td>
                            <td
                              style={{
                                color:
                                  order.invoiceStatus == "Paid"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {order.invoiceStatus || "Not Paid"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.paymentRequestObj);
  return {
    allUsers: state.users.users,
    rechargeUser: ownProps.paymentRequestObj
      ? state.users.users.find(
          (user) => user.uid === ownProps.paymentRequestObj.userId
        )
      : null,
    admin: state.admins.currentAdmin,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentSourcingRedux,
    updatePaymentRequestSourcingStatusRedux,
  })(MakePaymentModal)
);
