import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getMultipleOrders } from "../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      loader: false,
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success("Successfully Deleted !");
  };

  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      myData.forEach((payment) => {
        newData.push({
          Id: payment.Id,
          Name: payment.Name,
          Mobile: payment.Mobile,
          Amount: `${payment.amount}RMB`,
          PaymentId: payment.paymentId,
          PaymentMethod: payment.paymentMethod,
          "Approved by": payment.approvedBy || payment.Name,
          Invoices: payment.paidInvoice.map((invoice) => `${invoice},`),
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index][cellInfo.column.id],
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      myData.forEach((payment) => {
        newData.push({
          Id: payment.Id,
          Name: payment.Name,
          Mobile: payment.Mobile,
          Amount: `${payment.amount}RMB`,
          PaymentId: payment.paymentId,
          PaymentMethod: payment.paymentMethod,
          "Approved by": payment.approvedBy || payment.Name,
          // Invoices: payment.paidInvoice.map((invoice) => `${invoice},`),
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: null,
        style: {
          textAlign: "center",
        },
      });
    }
    columns.push({
      Header: <b>Invoices</b>,
      id: "delete",
      accessor: (str) => "delete",
      Cell: (row) => {
        const paymentObj = myData.find(
          (payment) => payment.paymentId === row.original["PaymentId"]
        );
        return (
          <div>
            <span style={{ cursor: "pointer", padding: "5px" }}>
              {this.state.paymentId !== row.original["PaymentId"] && (
                <i
                  className="icofont-eye"
                  onClick={async () => {
                    this.setState({
                      loader: true,
                      paymentId: row.original["PaymentId"],
                    });
                    const invoices = await getMultipleOrders(
                      paymentObj.paidInvoice
                    );
                    this.setState({
                      loader: false,
                      paymentId: null,
                    });
                    this.props.startToggleModal(invoices);
                  }}
                ></i>
              )}
              {this.state.paymentId === row.original["PaymentId"] && (
                <span>
                  <CircleLoader
                    loading={this.state.loader}
                    color="black"
                    size={12}
                  />
                </span>
              )}
            </span>
          </div>
        );
      },
      style: {
        textAlign: "center",
      },
      sortable: false,
    });

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default withRouter(connect(null)(Datatable));
