import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { DollarSign } from "react-feather";
import {
  getAllSourcingsAgentRedux,
  handleSourcingAgentPayRedux,
} from "../../../../actions";
import "./monthly-invoice.css";

export class MonthlyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentPaid: 0,
    };
  }

  componentDidMount = async () => {
    const { agent, month } = this.props.match.params;
    this.props.getAllSourcingsAgentRedux(agent, month);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleCashOut = async () => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (!this.state.agentPaid) {
      alert("Please enter an amount first");
      return;
    }
    this.props.handleSourcingAgentPayRedux({
      ...this.state.sourcingObj,
      agentPaid: this.state.agentPaid,
      paymentId: date.getTime().toString(),
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      receiveBy: currentAdmin.name,
    });
    this.setState({
      agentPaid: 0,
    });
  };

  render() {
    const { open, sourcingObj } = this.state;
    const { sourcings, allEmployees, currentAdmin } = this.props;
    let date = new Date();
    let totalBill = 0;
    let totalPaid = 0;
    let buyingCost = 0;
    let totalProfit = 0;
    let agentProfit = 0;
    let algProfit = 0;
    if (sourcings.length > 0) {
      sourcings.map((sourcing) => {
        totalBill += parseInt(sourcing.totalBill || 0);
        totalPaid += parseInt(sourcing.totalPaid || 0);
        buyingCost += parseInt(sourcing.buyingCost || 0);
        totalProfit += parseInt(sourcing.initialProfit || 0);
        agentProfit += parseInt(sourcing.agentPaid || 0);
        algProfit += parseInt(sourcing.finalProfit || 0);
      });
    }

    console.log(this.props);
    return (
      <Fragment>
        <Breadcrumb
          title={this.props.match.params.agent}
          parent="Business Consultancy/Sourcing"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <>
              <div className="card o-hidden widget-cards">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#00254c" }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        {this.props.match.params.month}{" "}
                        {this.props.match.params.agent}
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp className="counter" end={totalBill} />
                        RMB
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {this.props.match.params.month}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover table-responsive-md">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Customer
                          </th>

                          <th
                            scope="col"
                            colSpan={2}
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Product Details
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Paid
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Bill
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Buying Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Profit
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Profit
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            ZEX Profit
                          </th>

                          {currentAdmin ? (
                            currentAdmin.status === "Admin" ||
                            currentAdmin.status === "Accounts" ? (
                              <>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Agent Pay
                                </th>
                              </>
                            ) : null
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {sourcings.map((sourcing, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{sourcing.date}</td>
                            <td>{sourcing.agent}</td>
                            <td style={{ color: "#ff8084" }}>
                              <Link
                                to={`/users/list-user/${sourcing.customerUid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {sourcing.customer}
                              </Link>
                            </td>

                            <td colSpan={2}>
                              <pre>{sourcing.description}</pre>
                            </td>
                            <td>{sourcing.totalPaid || 0}RMB</td>
                            <td>{sourcing.totalBill || 0}RMB</td>
                            <td>{sourcing.buyingCost || 0}RMB</td>
                            <td>{sourcing.initialProfit || 0}RMB</td>
                            <td>{sourcing.agentPaid || 0}RMB</td>
                            <td>{sourcing.finalProfit || 0}RMB</td>

                            {currentAdmin ? (
                              currentAdmin.status === "Admin" ||
                              currentAdmin.status === "Accounts" ? (
                                <>
                                  <td>
                                    <div
                                      style={{
                                        color: "white",
                                        padding: "3px 7px",
                                        borderRadius: 5,
                                        backgroundColor: "darkorange",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#InvoiceInfoModal2"
                                      onClick={() => {
                                        this.setState({
                                          sourcingObj: sourcing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" /> pay
                                      now
                                    </div>
                                  </td>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                        <tr>
                          <td
                            colSpan={6}
                            style={{ fontWeight: "bold", textAlign: "end" }}
                          >
                            Total
                          </td>
                          <td style={{ fontWeight: "bold" }}>{totalPaid}RMB</td>
                          <td style={{ fontWeight: "bold" }}>{totalBill}RMB</td>
                          <td style={{ fontWeight: "bold" }}>{buyingCost}RMB</td>
                          <td style={{ fontWeight: "bold" }}>
                            {totalProfit}RMB
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {agentProfit}RMB
                          </td>
                          <td style={{ fontWeight: "bold" }} colSpan={2}>
                            {algProfit}RMB
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="InvoiceInfoModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  AGENT PAY
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Invoice Id:{sourcingObj && sourcingObj.id}</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{sourcingObj && sourcingObj.agent}</td>
                      <td>{sourcingObj && sourcingObj.customer}</td>
                      <td colSpan={2}>
                        <pre>{sourcingObj && sourcingObj.description}</pre>
                      </td>
                      <td>
                        Bill- {(sourcingObj && sourcingObj.totalBill) || 0}RMB
                      </td>
                      <td>
                        Profit - {sourcingObj && sourcingObj.initialProfit}RMB
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                      marginBottom: 5,
                    }}
                  >
                    AGENT PAY
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="agentPaid"
                    value={this.state.agentPaid}
                    onChange={this.handleChange}
                    id="exampleFormControlInput1"
                    placeholder="Enter amount"
                    style={{
                      borderColor: "gainsboro",
                      borderRadius: 5,
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleCashOut(sourcingObj);
                  }}
                >
                  Cash Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sourcings: state.sourcings.sourcings,
    currentAdmin: state.admins.currentAdmin,
  };
};

export default connect(mapStateToProps, {
  getAllSourcingsAgentRedux,
  handleSourcingAgentPayRedux,
})(MonthlyInvoices);
