import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./makePaymentModal.css";

class MakePaymentModal extends Component {
  render() {
    const { payableOrders, paymentRequestObj } = this.props;

    console.log(payableOrders);
    let total = 0;
    let totalWeight = 0;
    payableOrders.forEach((invoice) => {
      console.log(invoice.finalTotal);
      return (total += invoice.finalTotal);
    });
    payableOrders.map((invoice) => {
      console.log(invoice.finalTotal);
    });
    payableOrders.forEach(
      (invoice) => (totalWeight += parseFloat(invoice.grossWeight))
    );
    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ backgroundColor: "white" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => this.props.startToggleModal([])}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2 className="h2-xl mb-2 fw-6 pb-2 order-details-header">
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div className="table-responsive-md">
                            <table className="table">
                              <thead style={{ position: "sticky", top: -3 }}>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Parcel Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Carton
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Product
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Gross Weight
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Rate/Kg
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      backgroundColor: "rgb(0, 0, 102)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {payableOrders.length > 0 &&
                                  payableOrders.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.parcelId}
                                      >
                                        <th scope="row">{invoice.parcelId}</th>
                                        <td>{invoice.cartonNo}</td>
                                        <td>{invoice.productName}</td>
                                        <td>{invoice.grossWeight}Kg</td>
                                        <td>{invoice.ratePerKg}RMB</td>
                                        <td>{invoice.finalTotal}RMB</td>
                                      </tr>
                                    );
                                  })}

                                <tr className="table-light">
                                  <th scope="row"></th>
                                  <td></td>
                                  <td className="result-td">Total Weight</td>
                                  <td className="result-td">
                                    {parseFloat(totalWeight).toFixed(2)}Kg
                                  </td>
                                  <td className="result-td">Grand Total</td>
                                  <td className="result-td">
                                    {parseInt(total)}RMB
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null, {})(MakePaymentModal));
