import React from 'react';

//import images
import pro13 from '../images/electronics/product/13.jpg';
import pro12 from '../images/electronics/product/12.jpg';
import pro25 from '../images/electronics/product/25.jpg';
import pro16 from '../images/electronics/product/16.jpg';
import pro21 from '../images/electronics/product/21.jpg';
import pro3 from '../images/electronics/product/3.jpg';
import pro14 from '../images/electronics/product/14.jpg';
import pro20 from '../images/electronics/product/20.jpg';
import pro19 from '../images/electronics/product/19.jpg';
import jwel18 from '../images/jewellery/pro/18.jpg';
import jwel12 from '../images/jewellery/pro/12.jpg';
import jwel26 from '../images/jewellery/pro/26.jpg';
import furniture8 from '../images/furniture/8.jpg';
import cat3 from '../images/cat3.png';
import fashion12 from '../images/fashion/product/12.jpg';
import shoes from '../images/fashion/pro/shoes.jpg'

export const data = [
    {
        id:"1",
        image:<img src={pro13} style={{width:50,height:50}} />,
        file_name:"Honor_Mobile.jpg",
        url:"http://www.assets/images/electronics/product/13.jpg"
    },
    {
        id:"2",
        image:<img src={pro12} style={{width:50,height:50}} />,
        file_name:"Samsung_LED_TV.jpeg",
        url:"http://www.assets/images/electronics/product/12.jpg"
    },
    {
        id:"3",
        image:<img src={pro25} style={{width:50,height:50}} />,
        file_name:"Motorola_Bluetooth.jpg",
        url:"http://www.assets/images/electronics/product/25.jpg"
    },
    {
        id:"4",
        image:<img src={pro16} style={{width:50,height:50}} />,
        file_name:"headphones.jpg",
        url:"http://www.assets/images/electronics/product/16.jpg"
    },
    {
        id:"5",
        image:<img src={pro21} style={{width:50,height:50}} />,
        file_name:"Apple_6s.jpeg",
        url:"http://www.assets/images/electronics/product/21.jpg"
    },
    {
        id:"6",
        image:<img src={pro3} style={{width:50,height:50}} />,
        file_name:"Printer.jpeg",
        url:"http://www.assets/images/electronics/product/3.jpg"
    },
    {
        id:"7",
        image:<img src={pro14} style={{width:50,height:50}} />,
        file_name:"Canon_Camera.jpg",
        url:"http://www.assets/images/electronics/product/14.jpg"
    },
    {
        id:"8",
        image:<img src={pro20} style={{width:50,height:50}} />,
        file_name:"High_Quality_Headphones.jpg",
        url:"http://www.assets/images/electronics/product/20.jpg"
    },
    {
        id:"9",
        image:<img src={pro19} style={{width:50,height:50}} />,
        file_name:"Home_Theater_Speakers.jpg",
        url:"http://www.assets/images/electronics/product/9.jpg"
    },
    {
        id:"10",
        image:<img src={jwel18} style={{width:50,height:50}} />,
        file_name:"Diamond_Ring.jpg",
        url:"http://www.assets/images/jewellery/pro/18.jpg"
    },
    {
        id:"11",
        image:<img src={jwel12} style={{width:50,height:50}} />,
        file_name:"Diamond_Nacklace.jpeg",
        url:"http://www.assets/images/jewellery/pro/12.jpg"
    },
    {
        id:"12",
        image:<img src={jwel26} style={{width:50,height:50}} />,
        file_name:"Diamond_Earrings.jpeg",
        url:"http://www.assets/images/jewellery/pro/26.jpg"
    },
    {
        id:"13",
        image:<img src={furniture8} style={{width:50,height:50}} />,
        file_name:"Night_lamp.jpg",
        url:"http://www.assets/images/furniture/8.jpg"
    },
    {
        id:"14",
        image:<img src={cat3} style={{width:50,height:50}} />,
        file_name:"men's_shoes.jpg",
        url:"http://www.assets/images/cat3.png"
    },
    {
        id:"15",
        image:<img src={fashion12} style={{width:50,height:50}} />,
        file_name:"",
        url:"http://www.assets/images/fashion/product/12.jpg"
    },
    {
        id:"16",
        image:<img src={shoes} style={{width:50,height:50}} />,
        file_name:"Ledi's_red_top.jpeg",
        url:"http://www.assets/images/fashion/pro/shoes.jpg"
    },
    {
        id:"17",
        image:<img src={pro21} style={{width:50,height:50}} />,
        file_name:"Apple_6s.jpeg",
        url:"http://www.assets/images/electronics/product/21.jpg"
    },
    {
        id:"18",
        image:<img src={pro3} style={{width:50,height:50}} />,
        file_name:"Printer.jpg",
        url:"http://www.assets/images/electronics/product/3.jpg"
    },
    {
        id:"19",
        image:<img src={pro20} style={{width:50,height:50}} />,
        file_name:"High_Quality_Headphones.jpg",
        url:"http://www.assets/images/electronics/product/20.jpg"
    },
    {
        id:"20",
        image:<img src={pro25} style={{width:50,height:50}} />,
        file_name:"Motorola_Bluetooth.jpg",
        url:"http://www.assets/images/electronics/product/25.jpg"
    },
]

export default data
