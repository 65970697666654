import React, { Component } from "react";
import "./createLotModal.css";
import { uploadLot, updateLot } from "../../../firebase/firebase.utils";
import {
  uploadLotRedux,
  updateLotRedux,
  deleteLotRedux,
} from "../../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteLot } from "./../../../firebase/firebase.utils";
class DeleteLotModal extends Component {
  componentWillReceiveProps = (nextProps) => {
    const { singleLot, singleLotFromRedux } = nextProps;
    console.log(singleLot);
    console.log(singleLotFromRedux);
    console.log("create Lot modal component will receive props is called");
    if (singleLotFromRedux != null) {
      this.setState(
        {
          lotNo: singleLotFromRedux.lotNo,
          selectCountry: singleLotFromRedux.selectCountry,
          shipmentMethod: singleLotFromRedux.shipmentMethod,
          shipmentStatus: singleLotFromRedux.shipmentStatus,
          shippingLine: singleLotFromRedux.shippingLine,
          shipmentDate: singleLotFromRedux.shipmentDate,
          arrivalDate: singleLotFromRedux.arrivalDate,
          shipmentNotice: singleLotFromRedux.shipmentNotice,
        },
        () => {
          console.log(this.state);
        }
      );
    } else {
      this.setState({
        lotNo: "",
        selectCountry: "",
        shipmentMethod: "",
        shipmentStatus: "",
        shippingLine: "",
        shipmentDate: "",
        arrivalDate: "",
        shipmentNotice: "",
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let shipmentStatusScore;
    const { shipmentStatus } = this.state;
    if (shipmentStatus === "China Warehouse") shipmentStatusScore = 1;
    if (shipmentStatus === "China Customs") shipmentStatusScore = 2;
    if (shipmentStatus === "Ready to Fly") shipmentStatusScore = 3;
    if (shipmentStatus === "Local Customs") shipmentStatusScore = 4;
    if (shipmentStatus === "Local Warehouse") shipmentStatusScore = 5;

    console.log(this.state);
    console.log(this.props.singleLot);
    if (this.props.singleLot === null) {
      const uploadedLotObj = await this.props.uploadLotRedux({
        ...this.state,
        shipmentStatusScore,
      });
      if (uploadedLotObj) {
        toast.success("Successfully created new lot");
      }
    } else {
      await this.props.updateLotRedux({ ...this.state, shipmentStatusScore });
      toast.success("successfully updated lot");
    }

    this.setState({
      lotNo: "",
      selectCountry: "",
      shipmentMethod: "",
      shipmentStatus: "",
      shippingLine: "",
      shipmentDate: "",
      arrivalDate: "",
      shipmentNotice: "",
    });
    this.props.startToggleModal(null);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  render() {
    console.log(this.props.singleLot);
    return (
      <>
        <div
          className={
            this.props.toggleDeleteModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-4"
              style={{ backgroundColor: "rgb(68 0 97)" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() => this.props.startToggleDeleteModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "140%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            Are you sure you want to delete this lot?
                          </h2>
                          <div
                            className="form-row"
                            style={{ justifyContent: "center" }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: "200%",
                                background: "white",
                                padding: "10px",
                                borderRadius: "3px",
                                border: "1px solid darkgray",
                              }}
                            >
                              {this.props.singleLot && this.props.singleLot.Lot}
                            </div>
                          </div>

                          <div className="form-row">
                            <div
                              className="col pt-3"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "row",
                              }}
                            >
                              <button
                                onClick={() =>
                                  this.props.startToggleDeleteModal(null)
                                }
                                className="btn btn-secondary"
                              >
                                No
                              </button>

                              <button
                                style={{ marginLeft: "5px" }}
                                onClick={async () => {
                                  await this.props.deleteLotRedux(
                                    this.props.singleLot.Lot
                                  );
                                  this.props.startToggleDeleteModal(null);
                                  toast.success("Successfully deleted!");
                                }}
                                className="btn btn-primary"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    singleLotFromRedux: ownProps.singleLot
      ? state.lots.lots.find((lot) => lot.lotNo === ownProps.singleLot.Lot)
      : null,
    allLots: state.lots.lots,
  };
};
export default connect(mapStateToProps, {
  uploadLotRedux,
  updateLotRedux,
  deleteLotRedux,
})(DeleteLotModal);
