import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  getAllOrdersOfSingleLotRedux,
} from "../../actions/index";
import "./makePaymentModal.css";
import { getAllSourcingPurchasingByTrackingNo } from "../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
import { sendNotifications } from "../../firebase/fcmRestApi";
class MakePaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      paymentMethod: "",
      loader: false,
      invoicesToPay: [],
      allSourcingPurchasing: [],
    };
  }

  componentWillReceiveProps = async (nextProps) => {
    console.log("componet will received is getting called outside!");
    const { parcelIdArray } = nextProps;
    if (nextProps.parcelArray && nextProps.parcelArray.length > 0) {
      console.log("componet will received is getting called inside!");
      const invoiceArray = nextProps.parcelArray.filter(
        (parcel) => parcel.invoiceGenerated == true
      );
      this.setState({
        invoiceArray,
      });
      let invoicesToPay = invoiceArray.filter(
        (invoice) => parcelIdArray && parcelIdArray.includes(invoice.parcelId)
      );
      console.log(invoiceArray);
      console.log(invoicesToPay);
      let invoicesToPayWithPurchasingSourcingArray = [];
      for (let i = 0; i < invoicesToPay.length; i++) {
        const invoice = invoicesToPay[i];
        let allSourcingPurchsing = await getAllSourcingPurchasingByTrackingNo(
          invoice.trackingNo
        );
        invoicesToPayWithPurchasingSourcingArray.push({
          ...invoicesToPay[i],
          allSourcingPurchsing,
        });
        this.setState({
          invoicesToPay: invoicesToPayWithPurchasingSourcingArray,
        });
        console.log(allSourcingPurchsing);
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handlePayment = async (total, invoicesToPay, parcelsArray) => {
    const { currentAdmin } = this.props;
    const { paymentMethod } = this.state;
    // admin payment korle admin jabe, user payment korle user jabe
    if (this.state.loader) {
      return;
    }
    if (!paymentMethod) {
      alert("Selet a Payment Method first");
      return;
    }
    this.setState({
      loader: true,
    });
    await this.props.makePaymentRedux(
      total,
      invoicesToPay,
      null,
      currentAdmin,
      parcelsArray,
      paymentMethod
    );
    const message = {
      title: `Payment confirmation`,
      body: `Your Payment is successful for your invoices.`,
    };
    const userObj = this.props.allUser.find(
      (user) => user.uid === parcelsArray[0].customerUid
    );
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }
    this.setState({
      loader: false,
    });
    toast.success("Payment is successful");
    document.getElementById("modal-close-icon-payment-modal").click();
  };

  getAllDueSourcingPurchasingDue = (allSourcingPurchasing) => {
    if (allSourcingPurchasing && allSourcingPurchasing.length > 0) {
      let totalDue = 0;
      allSourcingPurchasing.map((sourcing) => {
        let due = sourcing.totalBill;
        if (sourcing.cashIns && sourcing.cashIns.length > 0) {
          let receivedCash = 0;
          sourcing.cashIns.map((cashIn) => {
            receivedCash += parseInt(cashIn.amount);
          });
          due = sourcing.totalBill - receivedCash;
        }

        totalDue += parseInt(due);
      });
      return (
        <div
          style={{ color: "#ff8084", fontWeight: "bold", cursor: "pointer" }}
          data-toggle="modal"
          data-target="#detailInfoModal"
          onClick={() => {
            this.setState({
              allSourcingPurchasing: allSourcingPurchasing,
            });
          }}
        >
          {totalDue} RMB
        </div>
      );
    }
    return "0 RMB";
  };

  render() {
    const { invoiceArray, invoicesToPay } = this.state;
    const { parcelIdArray, allCustomers } = this.props;
    console.log(parcelIdArray);

    let total = 0;
    invoicesToPay.forEach(
      (invoice) => (total += parseFloat(invoice.finalTotal))
    );
    console.log(total);
    let totalWeight = 0;
    let totalCbm = 0;
    invoicesToPay.forEach(
      (invoice) => (totalWeight += parseFloat(invoice.grossWeight))
    );
    invoicesToPay.forEach(
      (invoice) => (totalCbm += parseFloat(invoice.totalCbm))
    );
    console.log(invoicesToPay);
    const [shipmentMethod, lotNo] =
      this.props.match.params.shipmentMethodLotNo.split("-");
    let loanCustomer = null;
    if (invoicesToPay.length > 0) {
      let customerUid = invoicesToPay[0].customerUid;
      loanCustomer = allCustomers.find(
        (customer) => customer.uid == customerUid
      );
    }

    return (
      <>
        <div
          className={
            this.props.toggleModalMakePayment
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-make-payment"
              style={{
                backgroundColor: "white",
                boxShadow: "rgb(0 0 0) 1px 5px 24px 0px",
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => {
                        this.setState({
                          invoiceArray: [],
                        });
                        this.props.startToggleMakePaymentModal(null);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <h2
                              className="h2-xl mb-2 fw-6 pb-2"
                              style={{
                                color: "black",
                                textTransform: "none",
                                fontSize: "200%",
                              }}
                            >
                              Make Payment
                            </h2>
                          </div>
                          <div
                            className="row"
                            style={{
                              fontSize: 20,
                              padding: 5,
                              paddingLeft: 30,
                              paddingBottom: 0,
                              fontWeight: "bold",
                            }}
                          >
                            Lot No: {lotNo}
                          </div>
                          <div
                            className="table-responsive-md"
                            style={{
                              border: "1px solid gainsboro",
                              borderRadius: "10px",
                              marginLeft: "10px",
                              marginTop: "10px",
                              borderBottom: "none",
                            }}
                          >
                            <table className="table">
                              <thead>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Carton
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Customer
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Shipping Mark
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Consultancy Due
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    CBM
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Weight
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Rate/Kg
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total <br />{" "}
                                    <span style={{ fontSize: 13 }}>
                                      only shipment
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesToPay.length > 0 &&
                                  invoicesToPay.map((invoice) => {
                                    const userObj = this.props.allUser.find(
                                      (user) => user.uid === invoice.customerUid
                                    );

                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.cartonNo}
                                      >
                                        <th scope="row">{invoice.cartonNo}</th>
                                        <td>
                                          {userObj && userObj.displayName}
                                        </td>
                                        <td>{invoice.shippingMark}</td>
                                        <td>
                                          {this.getAllDueSourcingPurchasingDue(
                                            invoice.allSourcingPurchsing
                                          )}
                                        </td>
                                        <td>{invoice.totalCbm}</td>
                                        <td>{invoice.grossWeight}Kg</td>
                                        <td>{invoice.ratePerKg}RMB</td>
                                        <td>{invoice.finalTotal}RMB</td>
                                      </tr>
                                    );
                                  })}
                                <tr className="table-light">
                                  <th
                                    scope="row"
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total Weight
                                  </th>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {parseFloat(totalWeight).toFixed(2)}kg
                                  </td>

                                  <td></td>

                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total CBM
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {totalCbm}
                                  </td>
                                  <td></td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Grand Total
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {parseInt(total)}RMB
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <form className="rounded-field mt-4">
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Payment Method
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="paymentMethod"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.paymentMethod}
                                >
                                  <option value="">Payment Method</option>
                                  <option value="Cash">Cash</option>
                                  <option value="Loan">Loan</option>
                                  <option value="Bkash">Bkash</option>
                                  <option value="Rocket">Rocket</option>
                                  <option value="Nagad">Nagad</option>
                                  <option value="Brac Bank">Brac Bank</option>
                                  <option value="IBBL">IBBL</option>
                                  <option value="City Bank">City Bank</option>
                                </select>
                              </div>
                              <div
                                className="col"
                                style={{
                                  margin: "auto",
                                  paddingTop: 10,
                                  textAlign: "center",
                                  color:
                                    loanCustomer && loanCustomer.amount > 0
                                      ? "red"
                                      : "green",
                                  fontWeight: "bold",
                                }}
                              >
                                {loanCustomer && loanCustomer.amount > 0
                                  ? "Previous Due"
                                  : loanCustomer && "ZEX Debt"}{" "}
                                {loanCustomer
                                  ? `(${loanCustomer.customer}):- ${loanCustomer.amount}RMB`
                                  : null}
                              </div>
                            </div>
                          </form>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-3 btn btn-secondary"
                              style={{
                                minWidth: 70,
                                minHeight: 40,
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                this.handlePayment(
                                  total,
                                  parcelIdArray,
                                  invoicesToPay
                                );
                              }}
                            >
                              {!this.state.loader && "Pay"}
                              <CircleLoader
                                loading={this.state.loader}
                                color="white"
                                size={15}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  All Sourcing and Purchasing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Invoice Type</td>
                      <td style={{ fontWeight: "bold" }}>Invoice Id</td>
                      <td style={{ fontWeight: "bold" }}>Date</td>
                      <td style={{ fontWeight: "bold" }}>Agent</td>
                      <td style={{ fontWeight: "bold" }}>Total Bill</td>
                      <td style={{ fontWeight: "bold" }}>Total Paid</td>
                      <td style={{ fontWeight: "bold" }}>Total Due</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allSourcingPurchasing.length > 0 &&
                      this.state.allSourcingPurchasing.map((sourcing) => {
                        return (
                          <tr>
                            <td>{sourcing.category}</td>
                            <td>{sourcing.id}</td>
                            <td>{sourcing.date}</td>
                            <td>{sourcing.agent}</td>
                            <td>{sourcing.totalBill} RMB</td>
                            <td>{sourcing.totalPaid} RMB</td>
                            <td>
                              {parseInt(sourcing.totalBill) -
                                (parseInt(sourcing.totalPaid) || 0)}
                              RMB
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.ordersAlg.orders,
    allUser: state.users.users,
    currentAdmin: state.admins.currentAdmin,
    allCustomers: state.loans.allCustomers,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    getAllOrdersOfSingleLotRedux,
  })(MakePaymentModal)
);
